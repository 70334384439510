<template>
   <div>
     <el-card>
       <div slot="header" class="clearfix">
         <el-input placeholder="请输入金额" v-model="smallMoney" class="input-with-select">
           <template slot="prepend">金额转繁体</template>
           <el-button slot="append" icon="el-icon-sort" @click="convertSmallToBigMoney"></el-button>
         </el-input>
       </div>
       <div>
          转换结果：{{bigMoney}}
       </div>
     </el-card>
   </div>
</template>

<script>

import {api_getBigMoney} from "@/apis/Util";

export default {
  name: "CommonSmallUtil",
  data() {
    return {
      smallMoney: '',
      bigMoney:''
    }
  },
  methods: {
    convertSmallToBigMoney(){
      let that = this;
      api_getBigMoney(that.smallMoney).then((resp =>{that.bigMoney=resp.data.data.result;}));
    }
  }
}
</script>

<style scoped>

</style>