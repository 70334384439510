<template>
  <div>
    <!--<h2>这个是右侧的内容区域</h2>-->
    <!--<div style="height: 1200px;width: 80px;background-color: blueviolet"></div>-->
    <div class="header">
      <li v-if="!isCollapse" @click="changeMenuStutus" class="el-icon-s-fold  myfon"></li>
      <li v-else @click="changeMenuStutus" class="el-icon-s-unfold  myfon"></li>
      <a class="word">
        头区域
      </a>
    </div>
    <div class="contentbody">
      <!--***这里涉及到了子路由的概念，在一个组件里面在写一个路由出口***-->
      <!--在路由配置里面需要配置children路由-->
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  props: ['isCollapse'], //接收这个参数用于隐藏按钮
  name: "MyContent",
  methods:{
      changeMenuStutus(){
          //***点击切换按钮修改父组件值***
          this.$emit('changeMenuStutus')
      }
  }
}
</script>

<style lang="less" scoped>
.header{
  height: 50px;
  line-height: 50px; //设置行高
  color: floralwhite; //设置字体颜色
  background-color: cornflowerblue; //设置背景色
}

.myfon {
  font-size: 20px;
}
</style>