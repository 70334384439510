<template>
<div>
  <el-dialog
      title="增加文章"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose">
    <el-form status-icon
             :model="articleForm"
             :rules="rules"
             ref="articleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="标题" prop="title">
        <el-input type="text"  autocomplete="off" v-model="articleForm.title"></el-input>
      </el-form-item>
      <el-form-item label="摘要" prop="subContent">
        <el-input type="text"  autocomplete="off" v-model="articleForm.subContent"></el-input>
      </el-form-item>
      <el-form-item label="标签" prop="tagIds">
        <el-select v-model="articleForm.tagIds"
                   filterable
                   multiple
                   collapse-tags
                   placeholder="请选择文章标签">
          <el-option
              v-for="item in tagList"
              :key="item.key"
              :label="item.tagName"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否发布">
        <el-radio-group v-model="articleForm.valid">
          <el-radio label="1">发布</el-radio>
          <el-radio label="0">未发布</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="markdownContent" prop="markContent" type="hidden" v-if="false">
        <el-input  v-model="markContent"></el-input>
      </el-form-item>
      <el-form-item label="htmlContent" prop="htmlContent" type="hidden" v-if="false">
        <el-input  v-model="htmlContent"></el-input>
      </el-form-item>
    </el-form>
    <!--<span>这是一段信息</span>-->
    <span slot="footer" class="dialog-footer">
    <el-button @click="dialogChange">取 消</el-button>
    <el-button type="primary" @click="commitSaveArticle">确 定</el-button>
    <el-button type="primary" @click="getTestFormContent">获取文章表单内容到控制台</el-button>
  </span>
  </el-dialog>
</div>
</template>

<script>
import {api_getTagByPage} from '@/apis/Tag/index';
import {api_saveArticle,api_getArticleById} from "../../apis/Article";
export default {
  name: "ArticleDialog",
  props:['dialogVisible','markContent','htmlContent'],
  data() {
    return {
      articleForm: {
        articleId:'',
        title: '',
        subContent: '',
        htmlContent: '',
        markContent: '',
        valid: '1',
        tagIds:''
      },
      tagPageParam: {
        page: 0,
        pageSize: 100,
        tagName: ''
      },
      tagList: [],
      rules: {
        title: [
          {required: true, message: '请输入活动名称', trigger: 'blur'},
          {min: 2, max: 50, message: '长度在 2 到 50 个字符', trigger: 'blur'}
        ],
        subContent: [
          {required: true, message: '请输入活动名称', trigger: 'blur'},
          {min: 2, max: 80, message: '长度在 2 到 200 个字符', trigger: 'blur'}
        ]
      }
    }
  },
  methods:{
    truncateTextPage(){
      this.$emit('truncateTextPage')
    },
    initDownInput(){
      let that = this;
      api_getTagByPage(this.tagPageParam).then(resp => {
        // console.log("编辑时候先初始化下拉框：", resp.data.data.result);
        if (resp.data.status == 200) {
          that.tagList = resp.data.data.result.list;
        } else {
          that.$message({message: resp.data.data.message, type: 'error', showClose: true})
        }
      });
    },
    initDialog(){
      debugger
      let articleId =this.$route.params.articleId;
      if (null==articleId){
        return;
      }
      // 通过长度判断合理id
      if (articleId.toString().length>10){
        let that=this;
        api_getArticleById(articleId).then(resp => {
          // console.log("哈哈哈哈：",resp.data.data.result);
          if (resp.data.status==200) {
            let resultContent = resp.data.data.result;
            that.articleForm.articleId = articleId;
            that.articleForm.title = resultContent.title;
            that.articleForm.subContent = resultContent.subContent;
            that.articleForm.valid = resultContent.valid;
            that.articleForm.tagIds=resultContent.tagIds;
          } else {
            that.$message({message: resp.data.data.message, type: 'error', showClose: true})
          }
        });
      }
    },
    getTestFormContent(){
      this.articleForm.markContent = this.markContent;
      this.articleForm.htmlContent = this.htmlContent;
      //["1562788456344592384","1562785367592062976"]
      // console.log("you want to3333:"+JSON.stringify(this.articleForm));
    },
    dialogChange(){
      this.$emit('articleDialogClose')
    },
    handleClose(){
      // console.log("关闭前你可以做点什么")
      this.dialogChange()
    },
    commitSaveArticle(){
      this.articleForm.markContent = this.markContent;
      this.articleForm.htmlContent = this.htmlContent;
      // // console.log("you want to:"+JSON.stringify(this.articleForm));
      let that = this;
      api_saveArticle(that.articleForm).then(resp => {
        if (resp.status===200) {
          that.$message({message: "保存成功", type: 'success', showClose: true});
          that.dialogChange();
          // 清楚输入页面的markdown
          that.truncateTextPage();
        } else {
          that.$message({message: resp.data.data.message, type: 'error', showClose: true})
        }
      }).catch(err => {
        that.$message({message: err, type: 'error', showClose: true});
      })
    }
  },
  created() {
    this.initDownInput();
  },
  watch:{
    // 监听 resultContent 变量 item1为新值，item2为旧值
    tagList(newData,oldData){
      this.initDialog();
    }
  }
}
</script>

<style scoped>

</style>