<template>
  <div id="mainArea">

  </div>
</template>

<script>
import E from 'wangeditor';

export default {
  name: "WangEditor",
  data() {
    return {
      myEditor: '',
      mydefContent: ''
    }
  },
  methods:{
    getMyContent(val){
      this.$emit('getMyContent',val)
    }
  },
  mounted() {
    this.myEditor = new E('#mainArea')
    this.myEditor.config.onchangeTimeout=500 //触发回调频率
    this.myEditor.config.focus = false //防止自动光标
    this.myEditor.create()
    this.myEditor.config.onchange= myHtmlContent =>{ //箭头函数防止this指向问题
      // console.log("当前编辑器内容",myHtmlContent)
      this.getMyContent(myHtmlContent)
    }

  }
}
/*
editor.setHtml('<p>hello</p>')
editor.isEmpty()
editor.getHtml()
*/
</script>

<style scoped>

</style>
