<template>
  <div>
    <!--  1订单搜索-->
    <div class="searchOrder">
      <!--失去焦点 或者 用户按下回车触发搜索-->
      <el-input @change="searchInput" v-model="input" placeholder="请输入内容"></el-input>
      <el-button @click="searchInput" type="primary">查询</el-button>
      <el-button @click="dialogVisible = true" type="primary">添加</el-button>
    </div>
    <!--  2订单展示列表-->
    <!--width 没有给的均分剩下的宽度-->
    <div class="tableWrapper">
      <el-table
          :data="tableData"
          border>
        <!--选择框-->
        <el-table-column
            type="selection">
        </el-table-column>
        <!--数据区域-->
        <el-table-column
            prop="title"
            label="文章标题">
        </el-table-column>
        <el-table-column
            prop="valid"
            label="是否有效"
            :formatter = "validFormatter">
        </el-table-column>
        <el-table-column
            prop="saveTime"
            label="保存日期">
        </el-table-column>
        <el-table-column
            prop="updateTime"
            label="更新日期">
        </el-table-column>
        <el-table-column
            prop="topFlag"
            label="置顶权重">
        </el-table-column>
        <el-table-column
            label="操作">
          <template slot-scope="scope">
            <!--点击可以触发两个参数，一个是索引 一个是行数据-->
            <el-button
                size="mini"
                @click="handleEdit(scope.$index, scope.row)">编辑
            </el-button>
            <el-button
                size="mini"
                type="danger"
                @click="deleteArticleConfirm(scope.$index, scope.row)">删除
            </el-button>
            <el-button
                size="mini"
                @click="handlePreview(scope.$index, scope.row)">预览
            </el-button>
            <el-button
                size="mini"
                type="primary"
                @click="editTopWeight(scope.$index, scope.row)">置顶权重
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--  3订单分页-->
    <div>
      <MyPagination :ppageSize="pageSize" :ppage="page" :ptotal="total" @currentChange="currentChange"></MyPagination>
    </div>
    <!--  4 订单编辑添加-->
    <OrderDialog :dialogVisible="dialogVisible" @dialogChange="dialogChange"></OrderDialog>
    <!--  5 文章置顶权重配置  -->
    <div>
      <el-dialog
          :visible.sync="editArticleDialogVisible"
          title="文章权重编辑"
          :before-close="beforeCloseEditArticleDialog"
          @open="editTopWeight"
          width="680px"
      >
        <el-form ref="articleWeightTop">
          <el-form-item label="请输入权重">
<!--            @change="handleChange"-->
            <el-input-number v-model="articleWeightTop" controls-position="right"  :min="0" :max="20"></el-input-number>
          </el-form-item>
        </el-form>
        <div
            slot="footer"
            class="dialog-footer">
          <el-button
              @click="submitEditArticleWeightDialog"
              type="primary"
              class="btn-custom">
            <span>确 定</span>
          </el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>

import {api_getArticlesByPage,api_delArticleById,api_editArticleWeight} from '@/apis/Article/index';
import OrderDialog from "../Order/OrderList/OrderDialog";
import MyPagination from "../../components/MyPagination";


export default {
  name: "OrderList",
  components: {OrderDialog, MyPagination},
  data() {
    return {
      input: '',
      tableData: [],
      page: 0,
      pageSize: 10,
      total: 0,
      orderName: '',
      dialogVisible: false,
      editArticleDialogVisible : false,
      articleWeightTop: 0,
      aId:""
    }
  },
  methods: {
    beforeCloseEditArticleDialog(){
      this.editArticleDialogVisible=false;
      this.articleWeightTop=0;
      this.aId=0;
    },
    submitEditArticleWeightDialog(){
      console.log(this.articleWeightTop);
      let weightObj={};
      weightObj.weightNumber = this.articleWeightTop;
      weightObj.articleIdString = this.aId ;
      api_editArticleWeight(weightObj).then(resp => {
        this.$message({
          type: 'success',
          message: '编辑权重成功!'
        });
        this.getArticleList('');
      });
      this.editArticleDialogVisible=false;
      this.articleWeightTop=0;
      this.aId=0;
    },
    editTopWeight(mIndex, mRowData){
      this.aId = mRowData.articleId;
      this.editArticleDialogVisible=true;
      this.articleWeightTop=mRowData.topFlag;
    },
    handleEdit(mIndex, mRowData) {
      // console.log(mIndex, mRowData);
      this.$router.push('/article-add/'+mRowData.articleId);
    },
    handlePreview(mIndex, mRowData) {
                       this.$router.push('/blog-detail/'+mRowData.articleId);
    },
    deleteArticleConfirm(mIndex, mRowData){
      var that = this;
      this.$confirm('此操作将永久删除该文章《'+mRowData.title+'》, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
        that.handleDelete(mRowData);
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });

    },
    handleDelete( mRowData) {
      // console.log('真正的删除方法',mRowData);
      api_delArticleById(mRowData.articleId).then(resp => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
        this.getArticleList('');
      }).catch(error => {
        if (error !== 'error') {
          // console.log(error)
          this.$message({type: 'error', message: '删除失败', showClose: true})
        }
      });
    },
    getArticleList(articleName) {
      api_getArticlesByPage(this.page, this.pageSize,articleName).then(resp => {
        // console.log('查询到数据', resp.data)
        this.tableData = resp.data.data.result.list
        this.total = resp.data.data.result.total
        this.pageSize = resp.data.data.result.pageSize
        // console.log(this.tableData, this.total, this.pageSize)
      }).catch(error => {
        if (error !== 'error') {
          this.$message({type: 'error', message: '订单加载失败', showClose: true})
        }
      })
    },
    currentChange(num) {
      // console.log("父组件页码改变")
      this.page = num
      this.getArticleList(this.input)
    },
    searchInput(){
      this.getArticleList(this.input)
    },
    dialogChange(){
      this.dialogVisible=false
    },
    validFormatter(validRowObj){
      // 参数是形参代表当前行的所有数据
      // 使用点的方式来格式化返回当前所有列
      if (validRowObj.valid==1){
        return "已发布";
      }
      if (validRowObj.valid==0){
        return "未发布";
      }
    }
  },
  created() {
    // console.log('看看执行了没有')
    this.getArticleList('')
  }
}
</script>

<style lang="less" scoped>
.searchOrder {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  display: flex; // 这样就变成一行了
}

.searchOrder button {
  margin-left: 20px;
}

.tableWrapper {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}
</style>