import request from '../../request/article/index';

// 1 query by page
export function api_getTagByPage(tagParam) {
    return request({
        method: 'GET',
        url: `/markTag/page?page=${tagParam.page}&pageSize=${tagParam.pageSize}&tagName=${tagParam.tagName}`
    })
}

// 2 save page
export function api_saveTag(tagParam) {
    return request({
        method: 'POST',
        data:tagParam,
        url: '/markTag'
    })
}

// 3 save page
export function api_getTagByIdList(tagIdList) {
    return request({
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
        data:tagIdList,
        url: '/markTag/getTagList'
    })
}

// 4 ARTICLE TRACE TAG
export function api_getTagArticleTrace(tagParam) {
    return request({
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'GET',
        url: `/markTag/head-page-tag?page=${tagParam.page}&pageSize=${tagParam.pageSize}&tagName=${tagParam.tagName}&tagType=${tagParam.tagType}`
    })
}

// 5 编辑标签
export function api_editTagObj(tagObject) {
    return request({
        method: 'POST',
        data:tagObject,
        url: '/markTag/tag-edit'
    })
}