<template>
  <div>
    <el-descriptions title="关于本博客" direction="vertical" :column="4" border>
      <el-descriptions-item label="JDK" :span="1">jdk-11</el-descriptions-item>
      <el-descriptions-item label="SPRINGBOOT" :span="1">2.3.7</el-descriptions-item>
      <el-descriptions-item label="MYSQL" :span="1">5.7.25</el-descriptions-item>
      <el-descriptions-item label="REDIS" :span="1">7.0.2</el-descriptions-item>
      <el-descriptions-item label="RABBITMQ" :span="1">3.11.10</el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
export default {
  name: "DevEnv",
  data() {
    return {
      currentDate: new Date()
    };
  }
}
</script>

<style scoped>

.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 100%;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}
</style>