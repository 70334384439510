<template>
  <div>
    <div class="mycrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/goods' }">商品管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/goods-add' }">添加文章</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="articleForm">
      <!--<WangEditor @getMyContent="getMyContent"></WangEditor>-->
      <el-form :model="articleForm"
               :rules="rules" ref="articleForm" label-width="100px">
        <el-form-item label="文章标题" prop="title">
          <el-input v-model="articleForm.title"></el-input>
        </el-form-item>
        <el-form-item label="文章摘要" prop="subContent">
          <el-input v-model="articleForm.subContent"></el-input>
        </el-form-item>
        <el-form-item label="是否发布">
          <el-radio-group v-model="articleForm.valid">
            <el-radio label="1">发布</el-radio>
            <el-radio label="0">未发布</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="描述">
          <!--ref 用于获取子组件实例-->
          <WangEditor ref="myEditor2" @getMyContent="getMyContent"></WangEditor>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitForm('articleForm')">提交</el-button>
          <el-button @click="resetForm('articleForm')">重置</el-button>
          <el-button type="primary" @click="setForm()">设置默认值</el-button>
          <el-button type="primary" @click="setFormByRequest()">请求数据库默认值</el-button>
        </el-form-item>
      </el-form>
      <!--<el-button type="primary" @click="submitForm2()">确定</el-button>-->
    </div>

  </div>
</template>

<script>

import WangEditor from "../../components/Editor/WangEditor";
import {api_getArticleById,api_saveArticle} from "../../apis/Article/index";

export default {
  name: "ArticleAdd",
  components:{WangEditor},
  data() {
    return {
      articleForm: {
        title: '',
        subContent: '',
        htmlContent: '',
        valid: ''
      },
      rules: {
        title: [
          {required: true, message: '请输入活动名称', trigger: 'blur'},
          {min: 2, max: 5, message: '长度在 2 到 5 个字符', trigger: 'blur'}
        ],
        subContent: [
          {required: true, message: '请输入活动名称', trigger: 'blur'},
          {min: 2, max: 5, message: '长度在 2 到 5 个字符', trigger: 'blur'}
        ]
      }
    };
  },
  methods: {
    setForm(){
      // 设置值
       this.$refs.myEditor2.myEditor.txt.html("<p>泥潭我爱北京天安门</p>");
    },
    submitForm2() {
      // console.log(this.articleForm.description)
    },
    submitForm(articleForm) {
      let that = this;
      this.$refs[articleForm].validate((valid) => {
        if (valid) {
          // console.log('submit!',that.articleForm);
          // alert('submit!',that.articleForm);
          api_saveArticle(that.articleForm).then(resp => {
            // console.log("返回响应完整结果：",resp)
            // console.log("读取响应状态：",resp.status)
            if (resp.status===200) {
              that.$message({message: "保存成功", type: 'success', showClose: true})
            } else {
              that.$message({message: resp.data.data.message, type: 'error', showClose: true})
            }
          }).catch(err => {
            that.$message({message: err, type: 'error', showClose: true});
          })

        } else {
          alert("存在必填项没有填写")
          // console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(articleForm) {
      this.$refs[articleForm].resetFields();
      // 清空值
      this.$refs.myEditor2.myEditor.txt.clear();

    },
    getMyContent(textHtml){
      // // console.log("父亲接收到儿子容器的变化",textHtml)
      this.articleForm.htmlContent = textHtml
    },
    setFormByRequest(){
      let that = this;
      api_getArticleById("1557018358388408320").then(resp => {
        // console.log("返回响应完整结果：",resp)
        // console.log("读取响应状态：",resp.status)
        if (resp.status===200) {

          that.articleForm.title=resp.data.title;
          that.articleForm.subContent=resp.data.subContent;
          that.articleForm.htmlContent=resp.data.htmlContent;
          that.$refs.myEditor2.myEditor.txt.html(that.articleForm.htmlContent);
        } else {
          that.$message({message: "获取失败", type: 'error', showClose: true})
        }
      }).catch(err => {
        that.$message({message: err, type: 'error', showClose: true});
      })

    }
  }
}
</script>

<style scoped>
.mycrumb {
  padding: 10px;
  background-color: floralwhite;
  margin-top: 15px;
  margin-left: 20px;
  margin-right: 20px;
  border: 1px solid #eee;
}

.articleForm {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  background-color: #ffffff;
  padding: 10px;
  padding-right: 30px;
}
</style>
