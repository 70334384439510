<template>
<div class="loginPage">
  <div class="me-login-box me-login-box-radius">
    <h1>小码博客 登录</h1>
    <el-form ref="userForm" :model="userForm" :rules="rules">
      <el-form-item prop="userName">
        <el-input placeholder="用户名" v-model="userForm.userName"></el-input>
      </el-form-item>

      <el-form-item prop="userPassword">
        <el-input placeholder="密码" type="password" v-model="userForm.userPassword"></el-input>
      </el-form-item>

      <el-form-item size="middle" class="me-login-button">
        <el-button type="primary" @click.native.prevent="submitForm('userForm')">登录</el-button>
        <el-button type="primary" @click.native.prevent="resetForm('userForm')">重置</el-button>
      </el-form-item>
    </el-form>

  </div>
</div>
</template>

<script>
import {api_userLogin} from '@/apis/Sso/index';

export default {
  name: "Login",
  data() {
    return {
      userForm: {
        userName: '',
        userPassword: ''
      },
      rules: {
        userName: [
          {required: true, message: '请输入用户名', trigger: 'blur'},
          {min:2,max: 10, message: '大于2个字符小于10个字符', trigger: 'blur'}
        ],
        userPassword: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min:6,max: 10, message: '大于6个字符小于10个字符', trigger: 'blur'}
        ]
      }
    };
  },
  methods: {
    submitForm() {
      this.$refs['userForm'].validate((valid) => {
        if (valid) {

          // console.log('登陆参数', this.userForm)
          api_userLogin(this.userForm).then(resp => {
            // console.log('查询到数据', resp.data.data)
            let accessToken=resp.data.data.result;
            if (accessToken!==''){
              localStorage.setItem('accessToken', accessToken);
              this.$router.push("/blogAdmin");
            }else {
              this.$message({type: 'alert', message: '请检查用户名密码是否正确', showClose: true})
            }
          });
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    getTokenFromLocal(){
     let accessToken= localStorage.getItem('accessToken');
     alert(accessToken)
    }
  }
}
</script>

<style scoped>
.loginPage{
  background-image: url("https://bing.com/th?id=OHR.PenzancePool_ZH-CN4493022613_UHD.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
  width: 100%;
  height:100vh; /*这样就可以保证铺满屏幕没有滚动条了*/
}
.me-login-box {
  position: absolute;
  width: 300px;
  height: 260px;
  margin-top: 150px;
  margin-left: -180px;
  left: 50%;
  padding: 30px;
  background:rgba(255,255,255,0.5);
}
.me-login-box-radius {
  border-radius: 10px;
  box-shadow: 0px 0px 1px 1px rgba(161, 159, 159, 0.1);
}

.me-login-box h1 {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  vertical-align: middle;
}

.me-login-button {
  text-align: center;
}


</style>