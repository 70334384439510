<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <span>{{articleObj.title}}</span>
      <el-button style="float: right; padding: 3px 0"  @click="toArticleDetail()">See the article</el-button>
    </div>
    <div class="textSubject">
      <p>{{articleObj.subContent}}</p>
    </div>
    <div class="articleInfo">
      <span class="el-icon-thumb">&nbsp;{{articleObj.likeNum}}</span> &emsp; <span class="el-icon-view">&nbsp;{{articleObj.lookNum}}</span>
    </div>
  </el-card>
</template>

<script>
//
export default {
  name: "ArticleListItem",
  props: {
    // title: String,
    // id: String,
    // textSubject: String,
    articleObj:Object
  },
  methods:{
    toArticleDetail(){
      // this.$message({
      //   message: '恭喜你，这是一条成功消息'+this.id,
      //   type: 'success'
      // });
      this.$router.push('/blog-detail/'+this.articleObj.articleId)
    }
  }
}
</script>

<style scoped>
.articleInfo{
  text-align: right;
}
</style>