<template>
  <div class="contentArea">
    <el-button type="primary" @click="getMyEditorContent">获取输入内容</el-button>
    <el-button type="primary" @click="setMyEditorContent">设置MARK内容</el-button>
    <el-button type="primary" @click="setMyEditorHtmlContent">设置HTML内容</el-button>
    <MarkdownEditor :editor="meEditor" ref="myEditor"></MarkdownEditor>
  </div>
</template>

<script>
import MarkdownEditor from "../../components/Editor/MarkdownEditor";


export default {
  name: "UserAdd",
  components: {MarkdownEditor},
  data() {
    return {
      meEditor: {
        value: '',
        toolbarsFlag: true, //这个表示是否展示工具栏目
        subfield: true, //这个表示是否展示编辑窗口
        defaultOpen: 'preview' ,//默认打开预览窗口
        scrollStyle: true //开启滚动条模式
      }
    }
  },
  methods: {
    getMyEditorContent() {
      // 1 这个是获取markdown的文本
      // console.log("读取到：", this.$refs.myEditor.editor.value)
      // 2 这个是获取html的文本
      // console.log("读取到：", this.$refs.myEditor.editor.ref.d_render)
    },
    setMyEditorContent() {
      this.$refs.myEditor.editor.value = "# Hello world!!\n" +
          "## 文字\n" +
          "我爱北京天安门 ![测试美女图片1.png](http://152.136.104.164:9000/basefiles/PNG/20220714/997278477648568320.png)"
    },
    setMyEditorHtmlContent() {
      this.$refs.myEditor.editor.ref.d_render="<h1><a id=\"Hello_world_0\"></a>Hello world!!</h1>\n" +
          "<h2><a id=\"_1\"></a>文字</h2>\n" +
          "<p>我爱北京天安门 <img src=\"http://152.136.104.164:9000/basefiles/PNG/20220714/997278477648568320.png\" alt=\"测试美女图片1.png\" /></p>";
    }
  }
}
</script>

<style scoped>
.contentArea {
  padding: 20px;
  margin: 20px;
}

</style>