import { render, staticRenderFns } from "./ArticleAbout.vue?vue&type=template&id=ce730d2e&scoped=true"
import script from "./ArticleAbout.vue?vue&type=script&lang=js"
export * from "./ArticleAbout.vue?vue&type=script&lang=js"
import style0 from "./ArticleAbout.vue?vue&type=style&index=0&id=ce730d2e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ce730d2e",
  null
  
)

export default component.exports