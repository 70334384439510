import request from '../../request/article/index'
// 1获取文章列表 这里的filee只是一个形参 用于传递一个对象 下方的data表示把形参传递到请求体中
// new FormData(); 在里面使用append来添加多个键值
export function upload_file(filee) {
    return request({
        method: 'POST',
        url: '/oss/file',
        data:filee
    })
}
