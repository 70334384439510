import request from '../../request/article/index'
// 1获取文章byId 首页使用
export function api_getArticleById(id) {
    return request({
        method: 'GET',
        url: `/textContent/article?id=${id}`
    })
}

// 1获取文章byId 后台使用
export function api_getArticleByIdAdmin(id) {
    return request({
        method: 'GET',
        url: `/textContent/article-admin?id=${id}`
    })
}

// 2 保存文章
export function api_saveArticle(articleForm) {
    return request({
        method: 'POST',
        data:articleForm,
        url: '/textContent/article'
    })
}

// 3 get article list 只有管理员个月使用
export function api_getArticlesByPage(page,pageSize,title) {
    return request({
        method: 'GET',
        url: `/textContent/articles?page=${page}&pageSize=${pageSize}&title=${title}`
    })
}

// 4 首页调用这个接口
export function api_getHeadPageArticlesByPage(page,pageSize,title,tagIdStr) {
    return request({
        method: 'GET',
        url: `/textContent/head-page-articles?page=${page}&pageSize=${pageSize}&title=${title}&tagIdStr=${tagIdStr}`
    })
}

// 5 根据id删除文章
export function api_delArticleById(articleId) {
    return request({
        method: 'GET',
        url: `/textContent/temp/article/${articleId}`
    })
}

// 6 get article recent article list
export function api_getRecentArticles(page,pageSize,title) {
    return request({
        method: 'GET',
        url: '/textContent/recentArticles'
    })
}

// 7 get cardArticle
export function api_getCardArticle() {
    return request({
        method: 'GET',
        url: '/textContent/cardArticle'
    })
}

// 8 get article look rank
export function api_getViewMostArticles() {
    return request({
        method: 'GET',
        url: '/textContent/viewMostArticles'
    })
}

// 9 时间归档接口
export function api_getArticleDateArchive(flag) {
    return request({
        method: 'GET',
        url: `/textContent/articleDateArchive/${flag}`
    })
}

// 10 置顶文章
export function api_editArticleWeight(articleWeightObj) {
    return request({
        method: 'POST',
        data:articleWeightObj,
        url: '/textContent/articleTop'
    })
}