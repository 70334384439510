<template>
<div>
  <el-button type="primary" @click="getClickMonth">获取月维度账单明细</el-button>
  <div class="block-month" style="justify-content: center;align-items: center">
    <el-date-picker
        :clearable="false"
        @change="monthStrChange"
        v-model="monthStr"
        format="yyyy-MM"
        type="month"
        placeholder="选择月">
    </el-date-picker>
  </div>
  <div class="graph-month" id="graph-month-box-circle" style="height: 800px;width: 100%">

  </div>
</div>
</template>

<script>
import {api_getBookMoneyAnalysis,api_getBookMoneyAnalysisRawList } from '@/apis/Money/index';

export default {
  name: "MoneyBookAnalysis",
  data() {
    return {
      monthStr:new Date()
    }
  },
  methods: {
    getClickMonth(){
      let month = this.monthStr.getMonth()+1;//默认获取上一个月
      if (month<10){
        month = '0'+month+'';
      }
      let yearMonth =this.monthStr.getFullYear()+"-"+month;
      api_getBookMoneyAnalysisRawList(yearMonth).then((resp =>{
        const filename = yearMonth+"%E8%B4%A6%E5%8D%95%E6%98%8E%E7%BB%86.xlsx";
        const blob = new Blob([resp.data], { type: 'application/octet-stream' })
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          // 兼容IE，window.navigator.msSaveBlob：以本地方式保存文件
          window.navigator.msSaveBlob(blob, decodeURI(filename))
        } else {
          // 创建新的URL并指向File对象或者Blob对象的地址
          const blobURL = window.URL.createObjectURL(blob)
          // 创建a标签，用于跳转至下载链接
          const tempLink = document.createElement('a')
          tempLink.style.display = 'none'
          tempLink.href = blobURL
          tempLink.setAttribute('download', decodeURI(filename))
          // 兼容：某些浏览器不支持HTML5的download属性
          if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank')
          }
          // 挂载a标签
          document.body.appendChild(tempLink)
          tempLink.click()
          document.body.removeChild(tempLink)
          // 释放blob URL地址
          window.URL.revokeObjectURL(blobURL)
        }
      }));

    },
    initData(){
      let month = this.monthStr.getMonth()+1;//默认获取上一个月
      if (month<10){
        month = '0'+month+'';
      }
      let yearMonth =this.monthStr.getFullYear()+"-"+month;
      console.log(yearMonth)
      let that = this;
      api_getBookMoneyAnalysis(yearMonth).then((resp =>{
        that.drawPublicMoneyNumberChart(resp.data.data.result);
      }));
      },
    monthStrChange(nval){
      this.initData();
    },
    drawPublicMoneyNumberChart(dataList) {
      // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
      let myChart = this.$echarts.init(document.getElementById("graph-month-box-circle"));
      // 指定图表的配置项和数据
      // let option = {
      //   title: {
      //     text: "每月发布文章",
      //   },
      //   tooltip: {},
      //   legend: {
      //     data: ["发布量"],
      //   },
      //   xAxis: {
      //     data: dataList.map(item=>item.tagName),
      //   },
      //   yAxis: {},
      //   series: [
      //     {
      //       name: "发布量",
      //       type: "bar",
      //       data: dataList.map(item=>item.amount),
      //     },
      //   ],
      // };
      let pieList = [];
      let inde = 0;
      let totalAmount = 0;
      for (let item of dataList){
        let newItem = {};
        newItem.value=item.amount;
        newItem.name=item.tagName;
        pieList[inde] = newItem;
        inde = inde + 1;
        totalAmount = totalAmount+parseFloat(item.amount);
      }
      let option = {
        title: {
          text: '消费合计'+totalAmount.toFixed(2),
          left: 'center',
          top: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '5%',
          left: 'center'
        },
        series: [
          {
            name: '项目大类',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: pieList
          }
        ]
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      myChart.on('mousemove', function(params) {
       console.log("鼠标移入区域")
      });
    }
  },
  mounted() {
    this.initData();
  }
}
</script>

<style scoped>

</style>