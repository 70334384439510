<template>
  <div class="blogHome">
    <ArticleMenu/>
    <el-container>
      <el-main>
        <router-view></router-view>
        <div class="netNumber">
          <el-link type="primary"><a href="https://beian.miit.gov.cn" target="_blank">豫ICP备2022022636号</a></el-link>
        </div>
      </el-main>
      <el-aside width="20%" v-if="sideCardPercent">
        <HomeCardList></HomeCardList>
      </el-aside>
    </el-container>
  </div>
</template>

<script>
import ArticleMenu from "./BlogFaceModels/ArticleMenu";
import HomeCardList from "../../components/Article/HomeCardList";


export default {
  name: "BlogHome",
  components: {ArticleMenu,HomeCardList},
  data(){
    return {
      sideCardPercent: true
    }
  },
  watch:{
    $route(to,from){
      console.log(to.path,from.path)
      if (to.path.includes("blog-detail")){
        this.sideCardPercent=false;
      }else {
        this.sideCardPercent=true;
      }
    }
  },
  mounted() {
    console.log(returnCitySN["cip"]);
  }
}
</script>

<style scoped>

.blogHome{
  background-image: url("https://bing.com/th?id=OHR.CostadaMorte_ZH-CN5219249535_1920x1080.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
  width: 100%;
  height:100vh; /*这样就可以保证铺满屏幕没有滚动条了*/
}

.el-main{
  height:90vh;
  width: 80%;
}

.el-main::-webkit-scrollbar {
  /* 隐藏浏览器默认的滚动条 而且仍然可以使用滚动效果*/
  display: none !important;
}

.el-container{
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 80%;
}

.netNumber{
  text-decoration: none;
  width: 80%;
  color: red;
  text-align: center;
}
.netNumber a:hover{ color:white}

</style>