<template>
  <div>
    <div class="mycrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/goods' }">商品管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/goods-add' }">添加商品</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="goodsForm">
      <!--<WangEditor @getMyContent="getMyContent"></WangEditor>-->
      <el-form :model="goodsForm"
               :rules="rules" ref="goodsForm" label-width="100px">
        <el-form-item label="商品名称" prop="name">
          <el-input v-model="goodsForm.name"></el-input>
        </el-form-item>
        <el-form-item label="商品价格" prop="price">
          <el-input v-model="goodsForm.price"></el-input>
        </el-form-item>
        <el-form-item label="单位" prop="dw">
          <el-input v-model="goodsForm.dw"></el-input>
        </el-form-item>
        <el-form-item label="商品总数量" prop="totalNum">
          <el-input v-model="goodsForm.totalNum"></el-input>
        </el-form-item>
        <el-form-item label="是否有效">
          <el-radio-group v-model="goodsForm.valid">
            <el-radio label="1">有效</el-radio>
            <el-radio label="0">无效</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="上架时间" prop="upDate">
          <el-date-picker
              v-model="goodsForm.upDate"
              type="date"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="模块名称" prop="modelName">
          <el-input v-model="goodsForm.modelName"></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <!--ref 用于获取子组件实例-->
          <WangEditor ref="myEditor2" @getMyContent="getMyContent"></WangEditor>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitForm('goodsForm')">确定</el-button>
          <el-button @click="resetForm('goodsForm')">重置</el-button>
        </el-form-item>
      </el-form>
      <!--<el-button type="primary" @click="submitForm2()">确定</el-button>-->
    </div>

  </div>
</template>

<script>

import WangEditor from "../../components/Editor/WangEditor";


export default {
  name: "GoodsAdd",
  components:{WangEditor},
  data() {
    return {
      goodsForm: {
        name: '',
        price: '',
        dw: '',
        totalNum: '',
        valid: '',
        upDate: '',
        downDate: '',
        description: '',
        sellerId: '',
        modelName: '',
      },
      rules: {
        name: [
          {required: true, message: '请输入活动名称', trigger: 'blur'},
          {min: 2, max: 5, message: '长度在 2 到 5 个字符', trigger: 'blur'}
        ],
        price: [{required: true, message: '请输入价格', trigger: 'blur'}],
        dw: [{required: true, message: '请输入商品单位', trigger: 'blur'}],
        upDate: [{type: 'date', required: true, message: '请选择日期', trigger: 'change'}]
      }
    };
  },
  methods: {
    submitForm2() {
      // console.log(this.goodsForm.description)
    },
    submitForm(goodsForm) {
      this.$refs[goodsForm].validate((valid) => {
        if (valid) {
          // console.log(this.goodsForm);
          alert('submit!',this.goodsForm);
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(goodsForm) {
      this.$refs[goodsForm].resetFields();
      // 清空值
      this.$refs.myEditor2.myEditor.txt.clear()
      // 设置值
      // this.$refs.myEditor2.myEditor.txt.html("<p>泥潭</p>")
    },
    getMyContent(textHtml){
      // // console.log("父亲接收到儿子容器的变化",textHtml)
      this.goodsForm.description = textHtml
    }
  }
}
</script>

<style scoped>
.mycrumb {
  padding: 10px;
  background-color: floralwhite;
  margin-top: 15px;
  margin-left: 20px;
  margin-right: 20px;
  border: 1px solid #eee;
}

.goodsForm {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  background-color: #ffffff;
  padding: 10px;
  padding-right: 30px;
}
</style>