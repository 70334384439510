<template>
  <div class="my-pagination" style="text-align: center;margin: 10px" >
    <!--:pager-count="6" 只要奇数-->
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 50]"
        :page-size="ppageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :pager-count="7"
        :total="ptotal">
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    ptotal:{type:Number,default:10},
    ppageSize:{type:Number,default:2},
    ppage:{type:Number,default:1}},
  name: "MyPagination",
  methods:{
    handleSizeChange(pa){
      // console.log("子组件当前页大小改变")
      this.$emit('handleSizeChange',pa)
    },
    handleCurrentChange(pa){
      // console.log("子组件当前页改变",pa)
      this.$emit('currentChange',pa)
    }
  }
}
</script>

<style scoped>

</style>