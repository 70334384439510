<template>
  <div class="itemArticle">
    <div style="margin: 20px 10px;width: 200px;" @click="toArticleDetail">
      <el-image :src="articleObj.faceImgSrc" style="height: 130px;width: 200px;object-fit: cover;cursor: pointer; border-radius: 15px" lazy></el-image>
    </div>
    <div style="margin: 30px 20px;">
      <div style="font-size: 25px;color: #000000;cursor: pointer;" @click="toArticleDetail">{{articleObj.title}} <el-tag type="warning" size="small" v-if="articleObj.topFlag!=0">置顶</el-tag></div>
      <div style="display: flex;color: #cccccc;line-height: 40px;">
        <span class="icon-active"><i class="el-icon-alarm-clock el-icon--right"></i>{{articleObj.saveTime}}</span>
        <span class="icon-active" icon="el-icon-link" style="margin-left: 10px;"><i class="el-icon-view el-icon--right"></i>{{articleObj.lookNum}}人围观</span>
        <span class="icon-active" icon="el-icon-link" style="margin-left: 10px;"><i class="el-icon-thumb el-icon--right"></i>{{articleObj.likeNum}}次点赞</span>
      </div>
      <div style="overflow: hidden;text-overflow: ellipsis;line-height: 20px;color: gray;">
        {{articleObj.subContent}}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ArticleListItemPlus",
  props: {
    articleObj:Object
  },
  methods:{
    toArticleDetail(){
      this.$router.push('/blog-detail/'+this.articleObj.articleId)
    }
  }
}
</script>

<style scoped>
.itemArticle {
  border-radius: 10px;
  height: 170px;
  display: flex;
  margin: 0px 20px;
  border-bottom: 1px solid #cccccc;
  background-color: #eeeeee
}
.itemArticle:hover {
  transform: translateY(-1px);
  box-shadow: 2px 2px 20px rgba(104, 103, 103, 0.2);
}
</style>