<template>
  <div>
    <mavon-editor
        class="me-editor"
        ref="md"
        v-model="editor.value"
        @imgAdd="imgAdd"
        v-bind="editor">
    </mavon-editor>
  </div>
</template>

<script>
import {mavonEditor} from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'

 import {upload_file} from '@/apis/Oss/index'
export default {
  name: "MarkdownEditor",
  props: {
    editor: Object
  },
  data() {
    return {}
  },
  mounted() {
    this.$set(this.editor, 'ref', this.$refs.md)
  },methods: {
    imgAdd(pos, $file) {
      let that = this
      let formdata = new FormData();
      // 这里一定要写和后端参数一样
      formdata.append('multipartFile', $file);

      upload_file(formdata).then(resp => {
        // console.log("返回响应完整结果：",resp.data.data.result)
        // 第二步.将返回的url替换到文本原位置![...](./0) -> ![...](url)
        if (resp.data.status==200) {
          that.$refs.md.$img2Url(pos, resp.data.data.result.fileUploadLink);
        } else {
          that.$message({message: resp.data.data.message, type: 'error', showClose: true})
        }
      }).catch(err => {
        that.$message({message: err, type: 'error', showClose: true});
      })
    }
  },
  components: {
    mavonEditor
  }
}
</script>

<style scoped>
.me-editor {
  z-index: 6 !important;
  height: 1080px;
}

.v-note-wrapper.fullscreen {
  top: 60px !important
}
</style>