<template>
<div>
  <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
    <el-form status-icon ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="密码" prop="pass">
        <el-input type="password"  autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkPass">
        <el-input type="password"  autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="年龄" prop="age">
        <el-input ></el-input>
      </el-form-item>
    </el-form>
    <!--<span>这是一段信息</span>-->
    <span slot="footer" class="dialog-footer">
    <el-button @click="dialogChange">取 消</el-button>
    <el-button type="primary" @click="dialogChange">确 定</el-button>
  </span>
  </el-dialog>
</div>
</template>

<script>
export default {
  name: "OrderDialog",
  props:['dialogVisible'],
  methods:{
    dialogChange(){
      this.$emit('dialogChange')
    },
    handleClose(){
      // console.log("关闭前你可以做点什么")
      this.dialogChange()
    }
  }
}
</script>

<style scoped>

</style>