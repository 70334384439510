<template>
  <div>
    <div class="tableWrapper">
      <el-table
          :data="tableData"
          border>
        <!--数据区域-->
        <el-table-column
            prop="tagName"
            label="标签名称">
<!--          <template  slot-scope="scope">-->
<!--            <el-input-->
<!--                placeholder="请输入内容"-->
<!--                v-model="scope.row.tagName"-->
<!--                :disabled="true"-->
<!--                clearable>-->
<!--            </el-input>-->
<!--          </template>-->
        </el-table-column>
        <el-table-column
            prop="objNum"
            label="权重">
        </el-table-column>
        <el-table-column
            prop="tagTypeEnum"
            label="所属系统"
            :formatter = "tagTypeEnumFormatter">
<!--          <template slot-scope="scope">-->
<!--          <el-select v-model="scope.row.tagTypeEnum" clearable placeholder="请选择">-->
<!--            <el-option-->
<!--                v-for="item in sysTagTypeList"-->
<!--                :key="item.value"-->
<!--                :label="item.label"-->
<!--                :value="item.value">-->
<!--            </el-option>-->
<!--          </el-select>-->
<!--            </template>-->

        </el-table-column>
        <el-table-column
            label="操作">
          <template slot-scope="scope">
            <!--点击可以触发两个参数，一个是索引 一个是行数据-->
<!--            <el-button-->
<!--                size="mini"-->
<!--                @click="editTagInfo(scope.$index, scope.row)">变更保存-->
<!--            </el-button>-->
            <el-button
                size="mini"
                type="primary"
                @click="editTagInfoNew(scope.$index, scope.row)">变更保存（新）
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--  3订单分页-->
    <div>
      <MyPagination :ppageSize="tagPageParam.pageSize" :ppage="tagPageParam.page" :ptotal="tagPageParam.total"
                    @currentChange="currentChange"
                    @handleSizeChange="handleSizeChange"
      ></MyPagination>
    </div>
    <el-dialog
        :visible.sync="visibleEditTagDialog"
        title="标签所属系统编辑"
        :before-close="beforeCloseEditTagDialog"
        @open="editTagInfoNew"
        width="680px"
    >
      <el-form
          :model="tagObjToEdit"
          ref="tagObjToEdit"
      >
        <el-form-item label="标签所属系统">
          <el-select v-model="tagObjToEdit.tagTypeEnum" placeholder="请选择标签所属系统">
            <el-option
                v-for="item in sysTagTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div
          slot="footer"
          class="dialog-footer"
      >
        <el-button
            @click="submitEditTagDialog"
            type="primary"
            class="btn-custom"
        >
          <span>确 定</span>
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import {api_getTagByPage,api_editTagObj} from "@/apis/Tag";
import MyPagination from "../../components/MyPagination";

export default {
  name: "TagAdminPage",
  components: {MyPagination},
  data() {
    return {
      sysTagTypeList:[
        {
          value: 'MONEY',
          label: '账单'
        },{
          value: 'ARTICLE',
          label: '博客'
        }
      ],
      input: '',
      tableData: [],
      tagPageParam:{
        total: 0,
        page:0,
        pageSize:10,
        tagName:''
      },
      tagObjToEdit:{
        id:0,
        tagName:'',
        tagTypeEnum:''
      },
      visibleEditTagDialog: false
    }
  },
  methods :{
    handleSizeChange(siz){
      this.tagPageParam.pageSize = siz;
      this.getTagList();
    },
    currentChange(num) {
      this.tagPageParam.page = num;
      this.getTagList();
    },
    getTagList(){
      api_getTagByPage(this.tagPageParam).then(resp => {
        if (resp.data.status==200) {
          this.tableData = resp.data.data.result.list;
          this.tagPageParam.total = resp.data.data.result.total;
        }
      });
    },
    editTagInfo(mIndex, mRowData){
      // console.log("编辑标签属性："+mRowData.id);
      // console.log("编辑标签名称属性："+mRowData.tagName);
      // console.log("编辑标签类型属性："+mRowData.tagTypeEnum);
      let that = this;
      that.tagObjToEdit.id = mRowData.id;
      that.tagObjToEdit.tagName = mRowData.tagName;
      that.tagObjToEdit.tagTypeEnum = mRowData.tagTypeEnum;
      // that.$confirm('是否提交编辑的内容？', '提示', {
      //   confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
      // }).then(() => {
      //   api_editTagObj(that.tagObjToEdit).then(resp => {
      //     console.log("标签编辑接口返回参数："+resp.data);
      //   });
      //   that.$message({type: 'success', message: '编辑成功!'});
      // }).catch(() => {
      //   that.$message({type: 'info', message: '已取消编辑!'});
      // });
      api_editTagObj(that.tagObjToEdit).then(resp => {
        that.$message({type: 'success', message: '编辑成功!'});
      });

      that.getTagList();
    },
    editTagInfoNew(mIndex, mRowData){
      this.visibleEditTagDialog=true;
      // 打开弹窗，选择分类，进行保存，查询列表
      this.tagObjToEdit.id = mRowData.id;
      this.tagObjToEdit.tagName = mRowData.tagName;
    },beforeCloseEditTagDialog(){
      // 推出前清空对象
      this.tagObjToEdit={};
      this.visibleEditTagDialog=false;

    },submitEditTagDialog(){
      let that =this;
      that.tableData=[];
      api_editTagObj(that.tagObjToEdit).then(resp => {
        that.$message({type: 'success', message: '编辑成功!'});
      });
      that.visibleEditTagDialog=false;
      that.getTagList();
    },tagTypeEnumFormatter(rowObj){
      if (rowObj.tagTypeEnum === "MONEY") {
        return "账单";
      }
      if (rowObj.tagTypeEnum === "ARTICLE") {
        return "博客";
      }
      return rowObj.tagTypeEnum;
    }
  },
  mounted() {
    this.getTagList();
  }
}
</script>

<style scoped>
.tableWrapper {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}
</style>