<template>
<div class = "about">
  <!-- 名片分三大块
         最外层的一块a
         文字一大块b
         平面圆柱一块c -->
  <div class="a">
    <div class="b">
      <a href="#">小码の博客</a>
      <h2>NickName:mark</h2>
      <span>I'm a back-end developer enthusiast,</br>I'm glad to learn new knowledge every day to share with you,<br/>Just do it!!</span>
    </div>
    <div class="c">
      <!-- --i是用来计算平面圆柱的动效延迟和距离的
      --w则是用来计算平面圆柱的宽度 -->
      <div class="d" style="--i:1;--w:1.5"></div>
      <div class="d" style="--i:2;--w:1.6"></div>
      <div class="d" style="--i:3;--w:1.4"></div>
      <div class="d" style="--i:4;--w:1.7"></div>
      <div class="e" style="--i:1"></div>
    </div>
    <!-- 设置二维码 -->
    <div class="f">
      <h1><span>当前版本：郑大科技园版</span></h1>
      <br>
      <span>版本V1：河南工业大学</span>
      <br>
      <span>版本V2：郑大科技园</span>
    </div>
  </div>
<!--  <el-timeline>-->
<!--  <el-timeline-item timestamp="2022/08/21" placement="top">-->
<!--    <el-card>-->
<!--      <h4>完善页面整体布局</h4>-->
<!--      <p>mark 提交于 2022/08/21 20:46</p>-->
<!--    </el-card>-->
<!--  </el-timeline-item>-->
<!--  <el-timeline-item timestamp="2022/08/20" placement="top">-->
<!--    <el-card>-->
<!--      <h4>后台增加拦截器</h4>-->
<!--      <p>mark 提交于 2022/08/20 20:46</p>-->
<!--    </el-card>-->
<!--  </el-timeline-item>-->
<!--  <el-timeline-item timestamp="2022/08/19" placement="top">-->
<!--    <el-card>-->
<!--      <h4>后台集成GWT</h4>-->
<!--      <p>mark 提交于 2022/08/19 20:46</p>-->
<!--    </el-card>-->
<!--  </el-timeline-item>-->
<!--</el-timeline>-->
</div>
</template>

<script>


export default {
  name: "ArticleList"
}
</script>

<style scoped>
.el-card{
  line-height: 1.75;
}
.about{
  margin-right: 10px;
}
/*下面是copy的*/
.a{
  position: relative;
  width: 100%;
  height: 400px;
  border: #fff 10px solid;
  background-color: rgb(81, 90, 116);
  /*background-color: rgb(158, 180, 243);*/
  margin-right: 20px;
  border-radius: 20px;
  overflow: hidden;
}
.b{
  position: absolute;
  width: 200px;
  height: 300px;
  left: 0;
  margin: 75px 50px;
  transition: 1s;
}
.b a{
  text-decoration: none;
  color: #fff;
  font: 900 28px '';
}
.b h2{
  /* 鼠标放开时的动画，第一个值是动画的过渡时间
  第二个值是延迟一秒后执行动画 */
  transition: .5s 1s;
  opacity: 0;
  color: rgb(30,210,200);
}
.b span{
  transition: .5s 1s;
  color: #fff;
  font: 500 15px '';
  position: absolute;
  top: 70px;
}
.c{
  position: absolute;
  top: -130px;
  right: -240px;
}
.d,.e{
  position: absolute;
  right: calc(var(--i)*100px);
  width: calc(var(--w)*40px);
  height: 500px;
  overflow: hidden;
  border-radius: 100px;
  transform: rotateZ(220deg) translate(0,0);
  background: rgb(240,220,150);
  transition: .5s .5s;
}
.d:nth-child(2){
  background: rgb(240,190,230);
}
.e{
  left: -470px;
  top: -140px;
  width: 70px;
  background-color: rgb(90,220,150);
}
.a:hover .c div{
  /* 设置延迟动画 */
  transition: .5s calc(var(--i)*.1s);
  /* 移动的轨迹 */
  transform: rotateZ(220deg) translate(-200px,400px);
}
.a:hover .b{
  transition: 1s .5s;
  /*当a上面有鼠标的时候 b的样式发生改变*/
  left: 60%;
}
.a:hover .b span{
  transition: 1s .5s;
  top: 105px;
}
.a:hover .b h2{
  transition: 1s .5s;
  opacity: 1;
}
.f{
  width: 250px;
  height: 250px;
  position: absolute;
  /*background-image: url("goat.png");*/
  background-size: cover;
  margin-top: 15%;
  margin-left: 15%;
  opacity: 0;
  transition: .5s;
}
.a:hover .f{
  transition: 1s 1.3s;
  opacity: 1;
  color: #fff;
}

</style>