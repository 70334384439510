import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/Layout/index'
import Login from '../views/Login/Login'
import About from "../views/About/About";
import Home from "../views/Home/Home";
import Order from "../views/Order/Order";
import OrderBack from "../views/Order/OrderBack/OrderBack";
import OrderList from "../views/Order/OrderList/OrderList";
import Goods from "../views/Goods/Goods";
import GoodsAdd from "../views/Goods/GoodsAdd";
import UserAdd from "../views/Users/UserAdd";
import ArticleAdd from "../views/Goods/ArticleAdd";
import SystemSetting from "../views/System/SystemSetting";
import BlogHome from "../views/BlogFace/BlogHome";
import ArticleAbout from "../views/BlogFace/BlogFaceModels/ArticleAbout";
import ArticleList from "../views/BlogFace/BlogFaceModels/ArticleList";
import ArticleDetaile from "../views/BlogFace/BlogFaceModels/ArticleDetaile";
import ArticleEditAdd from "../views/Article/ArticleEditAdd";
import ArticleAdminList from "../views/Article/ArticleAdminList";
import ArticleTrace from "../views/BlogFace/BlogFaceModels/ArticleTrace";
import MoneyBook from "../views/money/MoneyBook";
import MoneyBookAnalysis from "../views/money/MoneyBookAnalysis";
import TagAdminPage from "../views/Tag/TagAdminPage";

Vue.use(VueRouter)

const routes = [
    {
        // 这个不写默认是 /
        path: '/blogAdmin',
        //name: 'Layout', 系统提示如果是子路由，父组件仅做为出口这里需要删掉
        component: Layout,
        children: [ {
            path: '/',
            name: 'Home',
            component: Home
        },{
            path: '/user-add',
            name: 'UserAdd',
            component: UserAdd
        },{
                path: '/article-add/:articleId?',
                name: 'ArticleEditAdd',
                component: ArticleEditAdd
        },{
            path: '/goods',
            name: 'Goods',
            component: Goods
        },{
            path: '/goods-add',
            name: 'GoodsAdd',
            component: GoodsAdd
        },{
                path: '/article-add',
                name: 'ArticleAdd',
                component: ArticleAdd
        },{
            path: '/system-setting',
            name: 'SystemSetting',
            component: SystemSetting
        },{
            path: '/tag-admin',
            name: 'TagAdminPage',
            component: TagAdminPage
        },{
            path: '/money-book',
            name: 'MoneyBook',
            component: MoneyBook
        },{
            path: '/money-analysis',
            name: 'MoneyBookAnalysis',
            component: MoneyBookAnalysis
        },{
                path: '/article-admin-list',
                name: 'ArticleAdminList',
                component: ArticleAdminList
            },{
            path: '/order',
            name: 'Order',
            component: Order,
            redirect: '/order/order-list',//配置重定向，这样父组件仅用作路由出口
            children: [
                {
                    path: 'order-list',
                    name: 'OrderList',
                    component: OrderList
                },
                {
                    path: 'order-back',
                    name: 'OrderBack',
                    component: OrderBack
                }
            ]
        },{
            path: '/about',
            name: 'About',
            component: About
        }]
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/',
        name: 'BlogHome',
        component: BlogHome,
        redirect: '/blog-list',
        children: [
            {
                path: '/blog-list/:articleTitle?',
                name: 'ArticleList',
                component: ArticleList
            },
            {
                path: '/blog-detail/:articleId?',
                name: 'ArticleDetaile',
                component: ArticleDetaile
            },
            {
                path: '/blog-about',
                name: 'ArticleAbout',
                component: ArticleAbout
            },
            {
                path: '/blog-trace',
                name: 'ArticleTrace',
                component: ArticleTrace
            }
        ]
    }
]

const router = new VueRouter({
    routes
})

// 解决重复点击相同路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

router.beforeEach((to, from, next) => {

        if (to.path === '/blogAdmin') {
            let accessToken = localStorage.getItem('accessToken');
            if (null == accessToken){
                next({path: '/login'})
            }else {
                if (accessToken.length === 0) {
                    next({path: '/login'})
                }else {
                    next()
                }
            }

        } else {
            next();
        }

})
export default router
