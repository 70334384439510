import request from '../../request/article/index'

// 1 用户登陆
export function api_userLogin(userForm) {
    return request({
        method: 'POST',
        data:userForm,
        url: '/sso-security/login'
    })
}

// 2 根据token获取用户ID
export function api_userByToken(token) {
    return request({
        method: 'GET',
        url: `/sso/getUser/${token}`
    })
}


// 3 获取已经登陆的用户信息
export function api_userByTokenPlusOnline() {
    return request({
        method: 'GET',
        url: '/sso-security/getUserByToken'
    })
}