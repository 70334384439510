<template>
<div>
  <el-card class="box-card-adv">
    <el-avatar class="user-avatar" :size="60" align="middle" :src=avatarUrl>  </el-avatar>
    <el-divider></el-divider>
    <el-row type="flex" justify="center" align="middle">
      <el-badge :value="articleAllInfo.num" class="itemBadge">
        <el-button type="primary" icon="el-icon-edit" circle></el-button>
      </el-badge>
      <el-badge :value="articleAllInfo.lookNum" class="itemBadge">
        <el-button type="success" icon="el-icon-view" circle></el-button>
      </el-badge>
      <el-badge :value="articleAllInfo.likeNum" class="itemBadge">
        <el-button type="info" icon="el-icon-thumb" circle></el-button>
      </el-badge>
    </el-row>
  </el-card>
  <!--当最新文章列表长度大于0的时候才显示-->
  <el-card class="box-card" v-if="newArticleList.length>0">
    <span>最热文章</span>
    <el-divider/>
    <div  v-for="(item,index) in newArticleList"  class="newArticleItem">
      <span @click="toArticleDetail(item.articleId)" style='cursor:pointer;'>{{item.title}}</span>
    </div>
  </el-card>

  <el-card class="box-card" v-if="newCommentList.length>0">
    <span>最新评论</span>
    <el-divider/>
    <div  v-for="(item,index) in newCommentList"  class="newArticleItem">
      <span @click="toArticleDetail(item.objIdString)" style='cursor:pointer;'>
        {{item.userName}}：{{item.commentContent}}
      </span>
    </div>
  </el-card>

  <el-card class="box-card-adv">
    <span>广告招租</span>
    <el-divider></el-divider>
    <span>rararara 一刀999</span>
    <br/>
    <span>系兄弟就来砍我</span>
  </el-card>
</div>
</template>

<script>

import {api_getRecentArticles,api_getViewMostArticles} from '@/apis/Article/index';
import {api_getCardArticle} from '@/apis/Article/index';
import {api_getCommentOfArticleRecent} from '@/apis/Comment/index';

export default {
  name: "HomeCardList",
  data(){
    return{
      avatarUrl:"http://makuan.cc:9000/basefiles/JPG/20221105/1588828675588567040.jpg",
      newArticleList:[],
      newCommentList:[],
      articleAllInfo:{
        num:0,
        lookNum:0,
        likeNum:0
      }
    }
  },
  methods:{
    toArticleDetail(articleId){
      this.$router.push('/blog-detail/'+articleId)
    }
  },
  created() {
    let that=this;
    // api_getRecentArticles().then(resp => {
    //   // console.log("返回响应完整结果：",resp.data.data.result)
    //   if (resp.data.status==200) {
    //     that.newArticleList=resp.data.data.result.list;
    //   } else {
    //     that.$message({message: resp.data.data.message, type: 'error', showClose: true})
    //   }
    // }).catch(err => {
    //   // console.log(err);
    // });
    api_getViewMostArticles().then(resp => {
      // console.log("返回响应完整结果：",resp.data.data.result)
      if (resp.data.status==200) {
        that.newArticleList=resp.data.data.result.list;
      } else {
        that.$message({message: resp.data.data.message, type: 'error', showClose: true})
      }
    });
    api_getCardArticle().then(resp => {
      if (resp.data.status==200) {
        // that.newArticleList=resp.data.data.result.list;
        let tempMap = resp.data.data.result;
        that.articleAllInfo.num=tempMap["ALL_NUM"];
        that.articleAllInfo.lookNum=tempMap["LOOK_NUM"];
        that.articleAllInfo.likeNum=tempMap["LIKE_NUM"];
      } else {
        that.$message({message: resp.data.data.message, type: 'error', showClose: true})
      }
    }).catch(err => {
      // console.log(err);
    });
    //查询最新评论
    api_getCommentOfArticleRecent(3).then(resp => {
      if (resp.data.status==200) {
        // that.newArticleList=resp.data.data.result.list;
        that.newCommentList = resp.data.data.result;
      } else {
        that.$message({message: resp.data.data.message, type: 'error', showClose: true})
      }
    });
  }
}
</script>

<style scoped>
.box-card{
  margin-right: 20px;
  margin-top: 20px;
  font-size: 14px;
  margin-bottom: 20px;
}
.box-card-adv{
  margin-top: 20px;
  margin-right: 20px;
}
.newArticleItem {
  line-height: 1.5;
}

.user-avatar{
  margin-right: 38%;
  margin-left: 38%;
}

.itemBadge{
  margin-right: 12px;
  margin-left: 12px;
}
</style>