<template>
  <div class="commentArea">
    <h2>评论：</h2>

    <el-form :inline="true" :model="commentUserInfo" ref="commentUserInfo">
      <!-- 此处 prop 表示要校验的属性，但是这里需要注意，因为最外层已经绑定了表单对象，这里的表单相的校验只需要给一个属性名 email
           即可，而不是commentUserInfo.email-->
      <el-form-item label="邮箱"
                    prop="email"
                    :rules="[
      { required: true, message: '请输入邮箱地址', trigger: 'blur' },
      { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }]">
        <el-input v-model="commentUserInfo.email" placeholder="邮箱" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="昵称"
                    prop="nickName"
                    :rules="[{ required: true, message: '昵称不能为空请输昵称', trigger: 'blur' }]">
        <el-input v-model="commentUserInfo.nickName" placeholder="昵称" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="邮箱验证码"
                    prop="checkCode"
                    :rules="[{ required: true, message: '邮箱验证码不能为空请输', trigger: 'blur' }]">
        <el-input v-model="commentUserInfo.checkCode" placeholder="邮箱验证码" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getCheckCode">1 获取验证码</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitComment('commentUserInfo')">2 提交评论</el-button>
      </el-form-item>
      <el-form-item
          prop="yourCommentContent"
          v-show="false">
        <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4}"
            placeholder="请输入一条友善评论"
            v-model="commentUserInfo.yourCommentContent">
        </el-input>
      </el-form-item>
    </el-form>

    <el-form :model="commentUserInfo">
      <el-form-item
          prop="yourCommentContent"
          :rules="[{ required: true, message: '请输入一条友善的评论', trigger: 'blur' }]">
        <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4}"
            placeholder="请输入一条友善评论"
            v-model="commentUserInfo.yourCommentContent">
        </el-input>
      </el-form-item>
    </el-form>

    <el-tree :data="commentTreeData" :props="defaultProps" @node-click="handleNodeClick"
             :render-content="renderContent"></el-tree>
  </div>
</template>

<script>

import {api_saveComment} from '@/apis/Comment/index';
import {api_getCommentMailCheckCode} from '@/apis/Mail/index';

export default {
  name: 'CommentArea',
  methods: {
    // render-content指定渲染函数，该函数返回需要的节点区内容
    // scoped slot 会传入两个参数node和data，分别表示当前节点的 Node 对象和当前节点的数据
    // <el-avatar shape="square" src={data.avatarSrc}></el-avatar>  {data.commentTime}-- <el-button size="mini">回复</el-button>
    renderContent(h, {node, data}) {
      return (
          <div class="comment-tree-node">
            <div style="text-align:center;">
              <span>{data.userName}：{node.label}&nbsp;&nbsp;&nbsp;{data.commentTime}</span>
            </div>
          </div>);
    },
    handleNodeClick(nodeData) {
      // 点击的时候吧点击的哪个对象找个地方存起来
      this.clickNodeData = nodeData;
      console.log(this.clickNodeData);
    },
    getCheckCode() {
      let that = this;
      let mailObj = new Object();
      mailObj.email=that.commentUserInfo.email;
      api_getCommentMailCheckCode(mailObj).then(resp => {
        if (resp.data.status==200) {
          that.$message({message:resp.data.message, type: 'success', showClose: true});
        }else {
          that.$message({message: resp.data.message, type: 'alert', showClose: true});
        }
      });
    },
    submitComment(commentUserInfo) {
      let commentObj = new Object();
      commentObj.objIdString=this.$route.params.articleId;
      commentObj.parentId="0";
      commentObj.userId="701";
      commentObj.avatarSrc="123.jpg";
      commentObj.email=this.commentUserInfo.email;
      commentObj.checkCode=this.commentUserInfo.checkCode;
      commentObj.userName=this.commentUserInfo.nickName;
      commentObj.commentContent=this.commentUserInfo.yourCommentContent;

      let that=this;
      api_saveComment(commentObj).then(resp => {
        if (resp.data.status==200) {
          that.$message({message: "评论成功，等待管理员审核", type: 'success', showClose: true});
          //调用父组件方法，重新请求查询评论接口
          that.$emit('refreshCommentChange');
          //清空表单
          that.$refs[commentUserInfo].resetFields();
        }else {
          that.$message({message: resp.data.message, type: 'alert', showClose: true});
        }
      });
    }
  },
  props: {
    commentTreeData: Array,
    defaultProps: Object
  },
  data() {
    return {
      clickNodeData: {},
      commentUserInfo: {
        email: '',
        nickName: '',
        checkCode: '',
        yourCommentContent: ''
      }
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.comment-tree-node {

}
</style>