import axios from 'axios'
import {Message} from "element-ui";
import router from "../../router";


const service = axios.create({
    baseURL: "http://makuan.cc:30003",
    timeout: 10000
})

// 添加请求拦截器
service.interceptors.request.use(function (config) {
    // 1 加入认证信息
    config.headers['Authorization'] = localStorage.getItem('accessToken');
    // 2 PUT DELETE 请求的支持
    // if(config.method.toUpperCase() == 'PUT') {
    //     config.method='POST'
    //     config.headers['X-HTTP-Method-Override']="PUT"
    // }
    // if(config.method.toUpperCase() == 'DELETE') {
    //     config.method='POST'
    //     config.headers['X-HTTP-Method-Override']="DELETE"
    // }
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});


// 添加响应拦截器
service.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    const res = response.data;
    // console.log("统一处理响应结果"+res.status)
    //0 为成功状态
    if (res.status === 40001) {
        //40001 用户未登录
        Message({
            type: 'warning',
            showClose: true,
            message: '未登录或登录超时，请重新登录哦'
        });
        router.replace({
            path:'/login',
            query:{redirect:router.currentRoute.fullPath}
        });
         // return Promise.reject('error');
    }
    return response;
}, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});

export default service
