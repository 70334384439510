<template>
<div>
  <!--<h2>-->
  <!--  关于页面-->
  <!--</h2>-->
  <DevEnv></DevEnv>
</div>
</template>

<script>
import DevEnv from "../../components/Card/DevEnv";
export default {
  name: "About",
  components:{DevEnv}
}
</script>

<style scoped>

</style>