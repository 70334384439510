<template>
<div>
  <h2>
    <!--订单页面 路由里面配置一个重定向 该页面仅用作路由的出口-->
    <router-view></router-view>
  </h2>
</div>
</template>

<script>
export default {
  name: "Order"
}
</script>

<style scoped>

</style>