<template>
  <div class="container">
    <!-- --d是自定义属性,可通过var函数对其调用 -->
    <div class="card" style="--d:-1">
      <div class="content">
        <div class="img">
          <img src="http://152.136.104.164:9000/basefiles/JPEG/20220828/1563914890918834176.jpeg" alt="">
        </div>
        <div class="detail">
          <span>路飞</span>
          <p>草帽海贼团船长：你小子还真是个天才</p>
        </div>
      </div>
      <a href="#">关注</a>
    </div>

    <div class="card" style="--d:0">
      <div class="content">
        <div class="img">
          <img src="http://152.136.104.164:9000/basefiles/JPEG/20220828/1563914916390842368.jpeg" alt="">
        </div>
        <div class="detail">
          <span>索隆</span>
          <p>草帽海贼团剑士：你干嘛~~~哈哈</p>
        </div>
      </div>
      <a href="#">关注</a>
    </div>

    <div class="card" style="--d:1">
      <div class="content">
        <div class="img">
          <img src="http://152.136.104.164:9000/basefiles/JPEG/20220828/1563915048976986112.jpeg" alt="">
        </div>
        <div class="detail">
          <span>山治</span>
          <p>草帽海贼团厨师：奥利给，干了兄弟们</p>
        </div>
      </div>
      <a href="#">关注</a>
    </div>

    <div class="card" style="--d:2">
      <div class="content">
        <div class="img">
          <img src="http://152.136.104.164:9000/basefiles/JPEG/20220828/1563915127746015232.jpeg" alt="">
        </div>
        <div class="detail">
          <span>娜美</span>
          <p>草帽海贼团海士：我只会心疼哥哥</p>
        </div>
      </div>
      <a href="#">关注</a>
    </div>

    <div class="card" style="--d:3">
      <div class="content">
        <div class="img">
          <img src="http://152.136.104.164:9000/basefiles/JPEG/20220828/1563915208368926720.jpeg" alt="">
        </div>
        <div class="detail">
          <span>乌索普</span>
          <p>草帽海贼团狙击手：瞎子也防？</p>
        </div>
      </div>
      <a href="#">关注</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "WordLeave"
}
</script>

<style scoped>

/*body{*/
/*  !* 100%的窗口高度 *!*/
/*  height: 100vh;*/
/*  !* 弹性布局 水平垂直居中 *!*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  !* 渐变背景 *!*/
/*  background: linear-gradient(200deg,#b1f4cf,#9890e3);*/
/*}*/

.container{
  /* 弹性布局 水平垂直居中 */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 300px;
  margin-left: 20%;
  /* 渐变背景 */
  /*background: linear-gradient(200deg,#b1f4cf,#9890e3);*/
}

.card{
  /* 绝对定位 */
  position: absolute;
  /*background-color: #fff;*/
  background-color: #dbd6d6;
  width: 430px;
  height: 100px;
  /* 弹性布局 */
  display: flex;
  /* 将元素靠边对齐 */
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  border-radius: 100px 20px 20px 100px;
  opacity: 0;
  /* 执行动画:动画名称 时长 线性的 无限次播放 */
  animation: animate 15s linear infinite;
  /* 这里我们需要给每一个卡片设置一个不同的动画延迟时间 */
  /* 用到自定义属性,我们设置一个自定义属性--d */
  /* 通过var函数调用自定义属性--d,计算出动画延迟时间 */
  animation-delay:  calc(3s * var(--d));

}
/* 鼠标移入,动画暂停 */
.container:hover .card{
  animation-play-state: paused;
}

.card .content{
  display: flex;
  align-items: center;
}

.card .img{
  width: 90px;
  height: 90px;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #fff;
  padding: 5px;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
.card .img img{
  width: 100%;
  height: 100%;
  /* 对图片进行剪切,保留原始比例 */
  object-fit: cover;
  border-radius: 50%;
}

.card .detail{
  margin-left: 100px;

}

.card .detail span{
  display: block;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
}

.card a{
  font-size: 14px;
  text-decoration: none;
  background: linear-gradient(to bottom,#fbc5ed,#a6c1ee);
  padding: 7px 18px;
  color: #fff;
  border-radius: 25px;
}

/* 定义动画 */
@keyframes animate{
  0%{
    opacity: 0;
    transform: translateY(100%) scale(0.5);
  }
  5%,20%{
    opacity: 0.4;
    transform: translateY(100%) scale(0.7);
  }
  25%,40%{
    opacity: 1;
    transform: translateY(0%) scale(1);
  }
  45%,60%{
    opacity: 0.4;
    transform: translateY(-100%) scale(0.7);
  }
  65%,100%{
    opacity: 0;
    transform: translateY(-100%) scale(0.5);
  }
}
</style>