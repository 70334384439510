<template>
  <div class="layout">
    <!--1 左侧菜单区域-->
    <MyMenu class="menu" :isCollapse="isCollapse"></MyMenu>
    <!--2 右侧内容区域-->
    <!--孩子调用父亲的方法，进而影响兄弟-->
    <!--接收参数进而修改按钮显示不显示-->
    <!--:class="{isActive:isCollapse}" 动态添加类-->
    <MyContent class="cont" :class="{isActive:isCollapse}" @changeMenuStutus="changeMenuStutus" :isCollapse="isCollapse"></MyContent>
  </div>
</template>

<script>
// 1 导入菜单 和 内容两块区域
import MyMenu from "./MyMenu";
import MyContent from "./MyContent";

export default {
  name: "index",
  components: {
    MyMenu,
    MyContent
  },
  data(){
    return{
      isCollapse:false //默认展开
    }
  },
  methods:{
    changeMenuStutus(){
      this.isCollapse=!this.isCollapse;
    }
  }
}
</script>
<!--npm install &#45;&#45;save-dev less-loader less-->
<style lang="less" scoped>
.layout {
  display: flex;

  .menu {
    //width: 200px; 这个使用菜单组件自身的宽度即可 用于动态展开
    min-height: 500px;
    background-color: #545c64;//这里要设置成和ele导航菜单一样的背景色
    position: fixed;//这里表示设置成固定大小 下面表示距离底部和顶部都是0距离贴紧上下
    top: 0;
    bottom: 0;
  }

  .cont {
    flex: 1;
    // 距离左对象边距是200px
    margin-left: 200px;
  }

  .isActive {
    margin-left: 64px;
  }

}

</style>