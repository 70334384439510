<template>
  <div class="trace">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="标签归档" name="first">
      <span v-for="(item,index) in tagList" class="tagItem">
         <!--&nbsp;<el-tag :type="tagType[index%4]">{{item.tagName}}||{{tagType[index%4]}}</el-tag>-->
        <!--dong tai de lai shezhi biaoqian de yangshi-->
         &nbsp;<el-tag :type="tagTypeList[index%4]" :taguid="item.id" @click="sayGetTagId($event)" >{{ item.tagName }}</el-tag>
      </span>

        <hr>

      <div v-for="(item,index) in articleList">
        <el-link icon="el-icon-share" :type="tagTypeList[index%4]" @click="seeArticleByFromTag(item.articleId)">{{item.title}}</el-link>
      </div>

      </el-tab-pane>
      <el-tab-pane label="发布时间归档" name="second">
        <div v-for="(item,index) in dataTimeListSave">
          <el-link icon="el-icon-share" :type="tagTypeList[index%4]">{{item.objectName}}({{item.num}})</el-link>
        </div>
      </el-tab-pane>
      <el-tab-pane label="更新时间归档" name="third">
        <div v-for="(item,index) in dataTimeListUpdate">
          <el-link icon="el-icon-share" :type="tagTypeList[index%4]">{{item.objectName}}({{item.num}})</el-link>
        </div>
      </el-tab-pane>
      <el-tab-pane label="小工具" name="four">
         <CommonSmallUtil></CommonSmallUtil>
      </el-tab-pane>
<!--      <el-tab-pane label="留言星球" name="second">-->
<!--        <WordLeave></WordLeave>-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane label="研发过程" name="third">-->
<!--        <el-steps :active="1" simple>-->
<!--          <el-step title="编写代码" icon="el-icon-edit"></el-step>-->
<!--          <el-step title="发布代码" icon="el-icon-upload"></el-step>-->
<!--          <el-step title="上线使用" icon="el-icon-picture"></el-step>-->
<!--        </el-steps>-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane label="OCR Beta" name="four">-->
<!--        <el-upload-->
<!--            class="upload-OCR"-->
<!--            drag-->
<!--            action="http://makuan.cc:9999/ocr/easyGetWords"-->
<!--            :multiple=false-->
<!--            name="multipartFile"-->
<!--            :on-success="ocrfile_upload_success">-->
<!--          <i class="el-icon-upload"></i>-->
<!--          <div class="el-upload__text">拖拽要识别图片文件，或<em>点击上传</em></div>-->
<!--          <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>-->
<!--        </el-upload>-->
<!--        <el-card class="box-card">-->
<!--          <div slot="header" class="clearfix">-->
<!--            <span>OCR识别结果</span>-->
<!--          </div>-->
<!--          <div class="resultItem">-->
<!--            识别结果：{{ ocrResult.content }}-->
<!--          </div>-->
<!--          <div class="resultItem">-->
<!--            耗时（s）：{{ ocrResult.takeTime }}-->
<!--          </div>-->
<!--          <div class="resultItem">-->
<!--            识别字数统计：{{ ocrResult.wordsNum }}-->
<!--          </div>-->
<!--        </el-card>-->
<!--      </el-tab-pane>-->
    </el-tabs>
  </div>
</template>

<script>

import WordLeave from "../../../components/Card/WordLeave";
import {api_getTagArticleTrace } from '@/apis/Tag/index';
import {api_getHeadPageArticlesByPage,api_getArticleDateArchive} from '@/apis/Article/index';
import CommonSmallUtil from "@/views/Util/CommonSmallUtil";

export default {
  name: "ArticleTrace",
  components: {WordLeave,CommonSmallUtil},
  data() {
    return {
      activeName: 'first',
      tagPageParam: {
        page: 0,
        pageSize: 100,
        tagName: '',
        tagType:"ARTICLE"
      },
      tagList: [],
      dataTimeListSave: [],
      dataTimeListUpdate: [],
      tagTypeList: ['warning', 'danger', 'info', 'success'],
      ocrResult: {},
      articleList: [],
      total:Number
    };
  },
  methods: {
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    ocrfile_upload_success(response, file, fileList) {
      this.ocrResult = new Object();
      this.ocrResult = response.data.result;
      // // console.log("回调完整结果：",response.data.result);
    },
    sayGetTagId(e){
      let tagUid= e.target.getAttribute('taguid');
      api_getHeadPageArticlesByPage(0,100,'',tagUid).then(resp => {
        if (resp.data.status==200) {
          this.articleList=resp.data.data.result.list;
          this.total=resp.data.data.result.total;
        } else {
          this.$message({message: resp.data.data.message, type: 'error', showClose: true})
        }
      })
    },
    seeArticleByFromTag(aid){
       this.$router.push('/blog-detail/'+aid)
    }
  },
  created() {
    let that = this;
    api_getTagArticleTrace(this.tagPageParam).then(resp => {
      // console.log("返回响应完整结果：", resp.data.data.result);
      if (resp.data.status == 200) {
        that.tagList = resp.data.data.result.list;
      } else {
        that.$message({message: resp.data.data.message, type: 'error', showClose: true})
      }
    });
    api_getArticleDateArchive(1).then((resp =>{that.dataTimeListSave=resp.data.data.result;}));
    api_getArticleDateArchive(0).then((resp =>{that.dataTimeListUpdate=resp.data.data.result;}));
  }
}
</script>

<style scoped>
.trace {
  background-color: #f7fbfa;
  height: 90%;
}

.upload-OCR {
  text-align: center;
}

.resultItem{
  margin-top: 5px;
}
.el-tag{
  cursor:pointer
}
</style>