<template>
  <div class="myHead">
    <div class="menuSpace">
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" router>
        <el-menu-item index="/blog-list">
          <span slot="title">文章列表</span>
        </el-menu-item>
        <el-menu-item index="/blog-trace">
          <span slot="title">文章归档</span>
        </el-menu-item>
        <el-menu-item index="/blog-about">
          <span slot="title">关于</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="searchSpace">
      <el-input placeholder="请输入内容" v-model="fieldVal" class="input-with-select" @change="searchByTitle">
        <el-select v-model="selectKey" slot="prepend" placeholder="请选择">
          <el-option label="文章名称" value="title"></el-option>
        </el-select>
        <el-button slot="append" icon="el-icon-search" @click="searchByTitle" ></el-button>
      </el-input>
    </div>
    <!--<div class="userInfoSpace">-->
    <!--  <div class="block"><el-avatar :size="50" :src="circleUrl"></el-avatar></div>-->
    <!--</div>-->
  </div>
</template>

<script>
export default {
  name: "ArticleMenu",
  data() {
    return {
      activeIndex: '1',
      selectKey:'',
      fieldVal:''
    };
  },
  methods: {
    getBlogListPage() {
      this.$router.push('/blog-list')
    },
    getBlogDetailPage() {
      this.$router.push('/blog-detail')
    },
    getAboutPage() {
      this.$router.push('/blog-about')
    },
    searchByTitle() {
      if (null!==this.fieldVal&&''!==this.fieldVal&&undefined!==this.fieldVal){
        this.$router.push('/blog-list/'+this.fieldVal);
      }else {
        this.$router.push('/blog-list');
      }
    }
  }
}
</script>

<style>
.myHead{
  display: flex;
  background-color: #FFF;
}
.menuSpace{
  width: 20%;
}
.searchSpace{
  padding: 10px;
}
</style>