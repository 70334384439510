import request from '../../request/article/index'
// 1 修改文章变动通知功能开关
export function api_putMsgOpenClose() {
    return request({
        method: 'POST',
        url: '/webSiteMessage/article/changeMessageFlag'
    })
}

// 2 获取文章变动消息功能通知的状态
export function api_getMsgOpenClose() {
    return request({
        method: 'GET',
        url: '/webSiteMessage/article/changeMessageFlag'
    })
}