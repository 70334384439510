<template>
  <div class="backHome">
    <div class="graph-box" id="monthPublicArticleNumber">
    </div>
    <div class="graph-box" id="monthUpdateArticleNumber">
    </div>
    <div class="graph-box" id="allArticleReducePie">
    </div>
    <div class="graph-box" id="allTagReducePie">

    </div>
  </div>
</template>

<script>

import {api_userByTokenPlusOnline} from '@/apis/Sso/index';
import {api_getTagArticleTrace } from '@/apis/Tag/index';
import {api_getHeadPageArticlesByPage,api_getArticleDateArchive} from '@/apis/Article/index';

export default {
  name: "Home",
  data() {
    return {
      dataTimeListSave: [],
      dataTimeListUpdate: [],
      tagPageParam:{
        page:0,
        pageSize:200,
        tagName:'',
        tagType: 'ARTICLE'
      }
    };
  },
  methods:{
    drawPublicArticleNumberChart(dataList) {

      // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
      let myChart = this.$echarts.init(document.getElementById("monthPublicArticleNumber"));
      // 指定图表的配置项和数据
      let option = {
        title: {
          text: "每月发布文章",
        },
        tooltip: {},
        legend: {
          data: ["发布量"],
        },
        xAxis: {
          data: dataList.map(item=>item.objectName),
        },
        yAxis: {},
        series: [
          {
            name: "发布量",
            type: "bar",
            data: dataList.map(item=>item.num),
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    drawUpdateArticleNumberChart(dataList) {
      // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
      let myChart = this.$echarts.init(document.getElementById("monthUpdateArticleNumber"));
      // 指定图表的配置项和数据
      let option = {
        title: {
          text: "每月更新文章",
        },
        xAxis: {
          data: dataList.map(item=>item.objectName)
        },
        yAxis: {},
        series: [
          {
            data: dataList.map(item=>item.num),
            type: 'line',
            label: {
              show: true,
              position: 'bottom',
              textStyle: {
                fontSize: 20
              }
            }
          }
        ]
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    drawAllArticleReducePieChart(dataList){
      let finalDataList = [];
      for(var i = 0; i < dataList.length; i++) {
        let obj = new Object();
        obj.name=dataList[i].tagName;
        obj.value=dataList[i].objNum;
        if (obj.value>2){
          finalDataList[i]=obj;
        }
      }
      // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
      let myChart = this.$echarts.init(document.getElementById("allArticleReducePie"));
      let option = {
        title: {
          text: "文章标签引用饼图",
        },
        series: [
          {
            type: 'pie',
            data: finalDataList
          }
        ]
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    drawAllTagReducePieChart(dataList){
      let finalDataList = [];
      for(var i = 0; i < dataList.length; i++) {
       let obj = new Object();
       obj.name=dataList[i].tagName;
       obj.value=dataList[i].objNum;
        if (obj.value>2){
          finalDataList[i]=obj;
        }
      }
      // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
      let myChart = this.$echarts.init(document.getElementById("allTagReducePie"));
      let option = {
        title: {
          text: "文章标签引用玫瑰图",
        },
        series: [
          {
            type: 'pie',
            data: finalDataList,
            roseType: 'area'
          }
        ]
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    initData(){
      let that = this;
      api_getArticleDateArchive(1).then((resp =>{
        that.dataTimeListUpdate=resp.data.data.result;
        that.drawPublicArticleNumberChart(that.dataTimeListUpdate);
        }));
      api_getArticleDateArchive(0).then((resp =>{
        that.dataTimeListSave=resp.data.data.result;
        that.drawUpdateArticleNumberChart( that.dataTimeListSave);
      }));
      api_getTagArticleTrace(that.tagPageParam).then(resp => {
          that.tableData = resp.data.data.result.list;
          that.drawAllTagReducePieChart(that.tableData);
      });
      api_getTagArticleTrace(that.tagPageParam).then(resp => {
        that.tableData = resp.data.data.result.list;
        that.drawAllArticleReducePieChart(that.tableData);
      });

    }
  },
  created() {
    let that = this;
    let accessToken = localStorage.getItem('accessToken');
    api_userByTokenPlusOnline().then(resp => {
       console.log("后台查询到用户实体：",resp.data.data);
       var respToken=resp.headers['new-authorization'];
       if(respToken!=null&&respToken.length>20){
            console.log("token被更新了：",respToken);
            localStorage.setItem('accessToken', respToken);
       }
    });
  },
  mounted() {
    this.initData();
  }
}
</script>

<style>
.backHome {
  background-image: url("https://s.cn.bing.net/th?id=OHR.SeitanLimania_ZH-CN3831790369_1920x1080.jpg");
  /*background-size: 100%;*/
  background-repeat: no-repeat;
  width: 100%;
  height: 94vh; /*这样就可以保证铺满屏幕没有滚动条了*/
}

/*1 划分一个区域分为4份，向左浮动*/
.graph-box{
  width: 50%;
  height: 50%;
  float: left;
}

/*2.1 第一个区域为红色*/
.backHome>div:nth-child(1){
  background-color: #efecec;
}

.backHome>div:nth-child(2){
  background-color: #efecec;
}

.backHome>div:nth-child(3){
  background-color: #efecec;
}

.backHome>div:nth-child(4){
  background-color: #efecec;
}

</style>