<template>
<div>
  <h1>商品管理页面</h1>
  <el-button type="success" @click="to_goodsAdd">添加商品</el-button>
  <el-button type="success" @click="to_goodsAdd2">添加文章</el-button>
</div>
</template>

<script>
export default {
  name: "Goods",
  methods:{
    to_goodsAdd(){
      this.$router.push('/goods-add')
    },
    to_goodsAdd2(){
      this.$router.push('/article-add')
    }
  }
}
</script>

<style scoped>

</style>