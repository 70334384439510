<template>
    <div>
        文章变更消息通知开关：<el-switch v-model="articleSwitchValue" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
    </div>
</template>
    
<script>
import { api_putMsgOpenClose, api_getMsgOpenClose } from "../../apis/Msg/index";
import axios from 'axios'
export default {
    data() {
        return {
            articleSwitchValue: true
        }
    },
    name: "SystemSetting",
    methods: {
        getArticleMsgOpenCloseStutus() {
            let that = this;
            api_getMsgOpenClose().then(resp => {
                
                if (resp.status === 200) {
                    let result = resp.data.data.result;
                    if(result==='1'){
                        that.articleSwitchValue=true;
                    }else{
                        that.articleSwitchValue=false;
                    }
                   
                }
            }).catch(err => {
                that.$message({ message: err, type: 'error', showClose: true });
            });
        },
        changeArticleMsgOpenCloseStutus(){
            let that = this;
            api_putMsgOpenClose().then(resp => {
                if (resp.status === 200) {
                    if(resp.data.data.result==='1'){
                      that.articleSwitchValue=true;
                    }else{
                      that.articleSwitchValue=false;
                    }
                    that.$message({ message: "修改文章更新消息通知系统设置成功。", type: 'success', showClose: true });
                }
            })
        }
    },
    watch: {
        articleSwitchValue(newV, oldV) {
            // console.log("新的值" + newV)
            this.changeArticleMsgOpenCloseStutus();
        }
    },
    mounted() {
        this.getArticleMsgOpenCloseStutus();
    }
}
</script>
    
<style scoped></style>