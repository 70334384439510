<template>
  <div>
    <!--  1订单搜索-->
    <div class="searchMoney">
      <!--失去焦点 或者 用户按下回车触发搜索 v-model="input"-->
      <el-input @change="searchInput"  placeholder="请输入内容"></el-input>
      <el-button @click="getBookMoneyByPage" type="primary">查询</el-button>
      <el-button @click="drawer = true" type="primary">添加账单</el-button>
    </div>

    <!--  2订单展示列表-->
    <!--width 没有给的均分剩下的宽度-->
    <div class="tableWrapper">
      <el-table
          :data="tableData"
          border>
        <!--选择框-->
        <el-table-column
            type="selection">
        </el-table-column>
        <!--数据区域-->
        <el-table-column
            prop="amount"
            label="项目金额">
        </el-table-column>
        <el-table-column
            prop="tagName"
            label="标签">
        </el-table-column>
        <el-table-column
            prop="payType"
            label="支付方式"
            :formatter="payTypeFormatter"
        >
        </el-table-column>
        <el-table-column
            prop="dataTime"
            label="保存日期">
        </el-table-column>
        <el-table-column
            label="操作">
          <template slot-scope="scope">
            <!--点击可以触发两个参数，一个是索引 一个是行数据-->
            <el-button
                size="mini"
                @click="handleEdit(scope.$index, scope.row)">编辑
            </el-button>
            <el-button
                size="mini"
                type="danger"
                @click="handleDel(scope.$index, scope.row)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--  3订单分页-->
    <div>
      <MyPagination :ppageSize="pageSize" :ppage="page" :ptotal="total" @currentChange="currentChange"></MyPagination>
    </div>
    <!--抽屉区域-->
    <el-drawer
        title="开始记账吧"
        :visible.sync="drawer"
        :direction="direction"
        :before-close="handleClose">
      <!--:inline="true"-->
      <el-form
               :model="ruleForm"
               status-icon :rules="rules"
               ref="ruleForm"
               label-width="100px" class="demo-ruleForm">
        <el-form-item label="项目金额" prop="amount">
          <el-input v-model.number="ruleForm.amount"></el-input>
        </el-form-item>
        <el-form-item label="支付方式">
          <el-select v-model="ruleForm.payType" placeholder="请选择支付方式">
            <el-option label="微信" value="wChat"></el-option>
            <el-option label="现金" value="realPay"></el-option>
            <el-option label="支付宝" value="aliPay"></el-option>
            <el-option label="银行卡" value="bankCard"></el-option>
          </el-select>
          &nbsp;&nbsp;
          <el-tag v-if="ruleForm.tagId.length>0">{{ruleForm.tagName}}</el-tag>
        </el-form-item>

        <el-form-item label="消费时间">
          <el-date-picker
              v-model="ruleForm.dataTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="记账描述">
        <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="ruleForm.description">
        </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
      <el-divider content-position="center">请选择一个标签</el-divider>
      <div class="tagList">
      <span  v-for="(item,index) in tagList"  class="tagItem">
         &nbsp;<el-tag :type="tagType[index%4]"
                       :tid="item.id"
                       @click="getTagIdFromClicked($event)"
          >{{item.tagName}}
          </el-tag>
      </span>
        <el-input
            class="input-new-tag"
            v-if="inputVisible"
            v-model="inputValue"
            ref="saveTagInput"
            size="small"
            @keyup.enter.native="handleInputConfirm"
            @blur="handleInputConfirm"
        >
        </el-input>
        <el-button v-else class="button-new-tag" size="small" @click="showInput">+ New Tag</el-button>
      </div>
    </el-drawer>
    <!--编辑弹窗区域-->
    <el-dialog
        :visible.sync="visibleEditDialog"
        title="账单编辑"
        :before-close="beforeCloseEditDialog"
        @open="openEditDialog"
        width="680px"
    >
      <el-form
          :model="editDialogForm"
          ref="editDialogForm"
      >
        <el-form-item
            label="金额"
            prop="amount"
        >
          <el-input
              placeholder="请输入项目金额"
              v-model.trim="editDialogForm.amount"
          ></el-input>
        </el-form-item>

        <el-form-item label="支付方式">
          <el-select v-model="editDialogForm.payType" placeholder="请选择支付方式">
            <el-option label="微信" value="wChat"></el-option>
            <el-option label="现金" value="realPay"></el-option>
            <el-option label="支付宝" value="aliPay"></el-option>
            <el-option label="银行卡" value="bankCard"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="标签" prop="tagId">
          <el-select v-model="editDialogForm.tagId"
                     @change="selectTagChanged"
                     filterable
                     collapse-tags
                     placeholder="请选择账单标签">
            <el-option
                v-for="item in tagList"
                :key="item.key"
                :label="item.tagName"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="消费时间">
          <el-date-picker
              v-model="editDialogForm.dataTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="记账描述">
          <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="editDialogForm.description">
          </el-input>
        </el-form-item>
        <!-- 1隐藏字段记账ID-->
        <el-form-item label="id" prop="markContent" type="hidden" v-if="false">
          <el-input  v-model="editDialogForm.id"></el-input>
        </el-form-item>
        <!-- 1隐藏字段记账USERID-->
        <el-form-item label="id" prop="markContent" type="hidden" v-if="false">
          <el-input  v-model="editDialogForm.userId"></el-input>
        </el-form-item>
        <!-- 1隐藏字段记账有效性-->
        <el-form-item label="id" prop="markContent" type="hidden" v-if="false">
          <el-input  v-model="editDialogForm.valid"></el-input>
        </el-form-item>
      </el-form>
      <div
          slot="footer"
          class="dialog-footer"
      >
        <el-button
            @click="submitEditDialog"
            type="primary"
            class="btn-custom"
        >
          <span>确 定</span>
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import {api_getTagByPage} from '@/apis/Tag/index';
import {api_saveMoneyBookItem,api_getBookMoneyByPage} from '@/apis/Money/index';
import {api_saveTag} from '@/apis/Tag/index';
import MyPagination from "../../components/MyPagination";
export default {
  components: {MyPagination},
  name: "Money",
  data() {
    var moneyReg = (rule,value,callback) => {
      if(value.length > 10){
        callback(new Error('长度在 0 到 10 个字符'));
      }else{
        setTimeout(() => {
          if (!/^\d+[.]?\d{0,2}$/.test(value) && value){
            callback(new Error('请输入正整数或小数保留两位小数'));
          }else{
            callback()
          }
        }, 500);
      }

    };

    return {
      editDialogForm:{
        id:'',
        tagName:'',
        tagId:'',
        amount:'',
        payType:'',
        dataTime:'',
        valid:'',
        userId:'',
        description:''
      },
      visibleEditDialog:false,
      drawer: false,
      direction: 'rtl',
      ruleForm: {
        id:'',
        amount: '',
        payType:'',
        tagId:'',
        tagName:'',
        description:'',
        dataTime:''
      },
      tagList:[],
      tableData:[],
      tagPageParam:{
        page:0,
        pageSize:80,
        tagName:''
      },
      page: 0,
      pageSize: 10,
      total: 0,
      tagType:['warning','danger','info','success'],
      inputVisible: false,
      inputValue: '',
      rules: {
        amount: [
          { validator:moneyReg, trigger: 'blur' }
        ],
        payType:[{required: true, message: '请选择支付方式', trigger: 'change' }]
      }
    };
  },
  methods: {
    searchInput(){},
    selectTagChanged(value){
      for(let item of this.tagList){
        if(item.id == value) {
          this.editDialogForm.tagName = item.tagName
        }
      }
    },
    submitEditDialog(){
      // 提交编辑弹窗
      api_saveMoneyBookItem(this.editDialogForm).then(resp => {
        if (resp.data.status==200) {
          this.$message({message: "编辑成功", type: 'success', showClose: true});
        }
      });
      this.editDialogForm={};
      this.visibleEditDialog=false;
    },
    beforeCloseEditDialog(){
      // 点击有上角的 xx 进行关闭的时候触发
      this.visibleEditDialog=false;
      this.editDialogForm={};
    },
    openEditDialog(){
    //  点击编辑按钮刚刚打开弹窗触发的方法
    },
    handleClose(done) {
      this.$confirm('离开页面可能会丢失记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        done();
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.api_saveMoneyBook();
          this.resetForm('formName');
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.ruleForm.tagId='';
      this.ruleForm.tagName='';
      this.ruleForm.payType='';
      this.ruleForm.dataTime='';
      this.ruleForm.description='';
    },
    getTagIdFromClicked(e){
      this.ruleForm.tagId=e.target.getAttribute('tid');
      this.ruleForm.tagName=e.target.innerHTML;
      // console.log(this.ruleForm.tagId);
    },
    getTagsByApi(){
      api_getTagByPage(this.tagPageParam).then(resp => {
        // console.log("返回响应完整结果：",resp.data.data.result);
        if (resp.data.status==200) {
          this.tagList = resp.data.data.result.list;
        }
      })
    },
    api_saveMoneyBook(){
      api_saveMoneyBookItem(this.ruleForm).then(resp => {
        if (resp.data.status==200) {
          this.$message({message: "保存成功", type: 'success', showClose: true});
          this.resetForm('ruleForm')
        }
      })
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue.length>0) {
        let obj = new Object();
        obj.tagName = inputValue;
        let that = this;
        api_saveTag(obj).then(resp => {
          if (resp.data.status==200) {
            that.$message({message: "新增标签成功", type: 'success', showClose: true});
            that.getTagsByApi();
          }
        })
      }
      this.inputVisible = false;
      this.inputValue = '';
    },handleEdit(mIndex, mRowData) {
      // 编辑
      console.log(mIndex, mRowData);
      this.editDialogForm=mRowData;
      this.visibleEditDialog=true;

    },handleDel(mIndex, mRowData) {
      // 编辑
      // console.log(mIndex, mRowData);
    },getBookMoneyByPage(){
      api_getBookMoneyByPage(this.page,this.pageSize).then(resp => {
        if (resp.data.status==200) {
          this.tableData = resp.data.data.result.list;
          this.total=resp.data.data.result.total;
          this.pageSize=resp.data.data.result.pageSize;
        }
      })
    },currentChange(num){
      this.page=num;
      this.getBookMoneyByPage();
    },
    payTypeFormatter(rowData){
      if(rowData.payType=="wChat"){
        return "微信";
      }
      if(rowData.payType=="realPay"){
        return "现金";
      }
      if(rowData.payType=="aliPay"){
        return "支付宝";
      }
      if(rowData.payType=="bankCard"){
        return "银行卡";
      }
    }
  },created() {
    this.getTagsByApi();
    this.getBookMoneyByPage();
  }
}
</script>

<style scoped>
.searchMoney {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
}
</style>