<template>
  <div>
    <div>
      <transition name="el-fade-in-linear">
      <el-menu
          default-active="1"
          class="el-menu-vertical-demo"
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#ffd04b"
          router
          :collapse="isCollapse">
        <!--用来做标题-->
        <el-menu-item>
          <span slot="title">后台管理系统</span>
        </el-menu-item>

        <el-menu-item index="/blogAdmin">
          <i class="el-icon-rank"></i>
          <span slot="title">欢迎页</span>
        </el-menu-item>
        <!--<el-menu-item index="/user-add">-->
        <!--  <i class="el-icon-menu"></i>-->
        <!--  <span slot="title">用户管理</span>-->
        <!--</el-menu-item>-->
        <el-menu-item index="/article-add">
          <i class="el-icon-edit"></i>
          <span slot="title">添加文章</span>
        </el-menu-item>
        <el-menu-item index="/article-admin-list">
          <i class="el-icon-document"></i>
          <span slot="title">管理文章</span>
        </el-menu-item>
        <el-submenu >
          <template slot="title">
            <i class="el-icon-document"></i>
            <span slot="title">账单管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/money-book">
              <i class="el-icon-edit"></i>
              <span slot="title">记账单</span>
            </el-menu-item>
            <el-menu-item index="/money-analysis">
              <i class="el-icon-date"></i>
              <span slot="title">分析</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-menu-item index="/system-setting">
          <i class="el-icon-menu"></i>
          <span slot="title">系统设置</span>
        </el-menu-item>
        <el-menu-item index="/tag-admin">
          <i class="el-icon-picture-outline"></i>
          <span slot="title">标签管理</span>
        </el-menu-item>
        <el-menu-item index="/about">
          <i class="el-icon-location-outline"></i>
          <span slot="title">关于</span>
        </el-menu-item>
      </el-menu>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  props: ['isCollapse'],//对外暴露一个属性 用于接收参数
  name: "MyMenu",
  data() {
    return {}
  }
}
</script>

<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
</style>