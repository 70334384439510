<template>
  <div>
    <div class="mycrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/goods' }">商品管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/goods-add' }">添加文章</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="articleForm">
      <el-form :model="articleForm"
               :rules="rules" ref="articleForm" label-width="100px">
        <el-form-item label="文章标题" prop="title"  v-if="false">
          <el-input v-model="articleForm.title" type="hidden"></el-input>
        </el-form-item>
        <el-form-item label="文章摘要" prop="subContent" v-if="false">
          <el-input v-model="articleForm.subContent" type="hidden"></el-input>
        </el-form-item>
        <el-form-item label="是否发布" v-if="false">
          <el-radio-group v-model="articleForm.valid" >
            <el-radio label="1">发布</el-radio>
            <el-radio label="0">未发布</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="描述">
          <!--ref 用于获取子组件实例-->
          <MarkdownEditor :editor="meEditor" ref="myEditor"></MarkdownEditor>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="testArticleDialog()">提交（升级布局）</el-button>
          <el-button @click="resetForm('dialogSuccessRest')">清空</el-button>
          <!--<el-button type="primary" @click="submitForm('articleForm')">提交</el-button>-->
          <!--<el-button @click="resetForm('articleForm')">重置</el-button>-->
          <!--<el-button type="primary" @click="getMyEditorContent">获取输入内容</el-button>-->
          <!--<el-button type="primary" @click="setMyEditorContent">设置MARK内容</el-button>-->
          <!--<el-button type="primary" @click="setMyEditorHtmlContent">设置HTML内容</el-button>-->
        </el-form-item>
      </el-form>
      <!--<el-button type="primary" @click="submitForm2()">确定</el-button>-->
    </div>
    <ArticleDialog
        :dialogVisible="articleDialogVisible"
        :markContent="articleForm.markContent"
        :html-content="articleForm.htmlContent"
        @truncateTextPage="dialogSuccessRest"
        @articleDialogClose="articleDialogCloseFather"></ArticleDialog>
  </div>
</template>

<script>
import MarkdownEditor from "../../components/Editor/MarkdownEditor";
import ArticleDialog from "./ArticleDialog";
import {api_saveArticle,api_getArticleById,api_getArticleByIdAdmin} from "../../apis/Article";

export default {
  name: "ArticleEditAdd",
  components: {MarkdownEditor,ArticleDialog},
  data() {
    return {
      articleDialogVisible:false,
      meEditor: {
        value: '',
        toolbarsFlag: true, //这个表示是否展示工具栏目
        subfield: true, //这个表示是否展示编辑窗口
        defaultOpen: 'preview' ,//默认打开预览窗口
        scrollStyle: true //开启滚动条模式
      },
      articleForm: {
        articleId:'',
        title: '',
        subContent: '',
        htmlContent: '',
        markContent: '',
        valid: ''
      },
      rules: {
        title: [
          {required: true, message: '请输入活动名称', trigger: 'blur'},
          {min: 2, max: 50, message: '长度在 2 到 50 个字符', trigger: 'blur'}
        ],
        subContent: [
          {required: true, message: '请输入活动名称', trigger: 'blur'},
          {min: 2, max: 80, message: '长度在 2 到 80 个字符', trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    testArticleDialog(){
      let markDownText= this.$refs.myEditor.editor.value;
      let htmlText = this.$refs.myEditor.editor.ref.d_render ;
      if(markDownText.length !=0){
        this.articleForm.markContent=markDownText;
        this.articleForm.htmlContent=htmlText;
        // 1.将提交窗口置为显示状态
        this.articleDialogVisible = true;
      }
    },
    articleDialogCloseFather(){
      this.articleDialogVisible = false;
    },
    getMyEditorContent() {
      // 1 这个是获取markdown的文本
      // console.log("读取到：", this.$refs.myEditor.editor.value)
      // 2 这个是获取html的文本
      // console.log("读取到：", this.$refs.myEditor.editor.ref.d_render)
    },
    setMyEditorContent() {
      this.$refs.myEditor.editor.value = "# Hello world!!\n" +
          "## 文字\n" +
          "我爱北京天安门 ![测试美女图片1.png](http://152.136.104.164:9000/basefiles/PNG/20220714/997278477648568320.png)"
    },
    setMyEditorHtmlContent() {
      this.$refs.myEditor.editor.ref.d_render="<h1><a id=\"Hello_world_0\"></a>Hello world!!</h1>\n" +
          "<h2><a id=\"_1\"></a>文字</h2>\n" +
          "<p>我爱北京天安门 <img src=\"http://152.136.104.164:9000/basefiles/PNG/20220714/997278477648568320.png\" alt=\"测试美女图片1.png\" /></p>";
    },
    submitForm(articleForm) {
      let that = this;
      this.$refs[articleForm].validate((valid) => {
        if (valid) {
          // 1 获取到markdown文本
          that.articleForm.markContent=that.$refs.myEditor.editor.value;
          // 2 这个是获取html的文本
          that.articleForm.htmlContent=that.$refs.myEditor.editor.ref.d_render;
          // console.log('submit!',that.articleForm);
          // alert('submit!',that.articleForm);
          api_saveArticle(that.articleForm).then(resp => {
            // console.log("返回响应完整结果：",resp)
            // console.log("读取响应状态：",resp.status)
            if (resp.status===200) {
              that.$message({message: "保存成功", type: 'success', showClose: true});
              that.$refs.myEditor.editor.value='';
              that.$refs.myEditor.editor.ref.d_render='';
              that.resetForm('articleForm');
              //修复文章修改后地址栏里面仍有参数的问题
              that.$router.push("/article-add");
            } else {
              that.$message({message: resp.data.data.message, type: 'error', showClose: true})
            }
          }).catch(err => {
            that.$message({message: err, type: 'error', showClose: true});
          })

        } else {
          alert("存在必填项没有填写")
          // console.log('error submit!!');
          return false;
        }
      });
    },
    dialogSuccessRest(){
      // console.log("看看对话框是否调用这个玩意")
      // 清空值
      // 1 这个是获取markdown的文本
      this.$refs.myEditor.editor.value='';
      // 2 这个是获取html的文本
      this.$refs.myEditor.editor.ref.d_render='';
    }
    ,
    resetForm(articleForm) {
      this.$refs[articleForm].resetFields();
      this.articleForm.valid='';
      // 清空值
      // 1 这个是获取markdown的文本
      this.$refs.myEditor.editor.value='';
      // 2 这个是获取html的文本
      this.$refs.myEditor.editor.ref.d_render='';
    }
  },
  created() {
    let articleId =this.$route.params.articleId;
    if (null==articleId){
      return;
    }
    // 通过长度判断合理id
    if (articleId.toString().length>10){
      let that=this;
      api_getArticleByIdAdmin(this.$route.params.articleId).then(resp => {
        // console.log("返回响应完整结果：",resp.data.data.result);
        if (resp.data.status==200) {
          let resultContent = resp.data.data.result;
          // 1 这个是获取markdown的文本
          that.$refs.myEditor.editor.value=resultContent.markContent;
          // 2 这个是获取html的文本
          that.$refs.myEditor.editor.ref.d_render=resultContent.htmlContent;
          that.articleForm.articleId = articleId;
          that.articleForm.title = resultContent.title;
          that.articleForm.subContent = resultContent.subContent;
          that.articleForm.valid = resultContent.valid;

        } else {
          that.$message({message: resp.data.data.message, type: 'error', showClose: true})
        }
      }).catch(err => {
        that.$message({message: err, type: 'error', showClose: true});
      });
    }
  }
}
</script>

<style scoped>
.contentArea {
  padding: 20px;
  margin: 20px;
}
.mycrumb {
  padding: 10px;
  background-color: floralwhite;
  margin-top: 15px;
  margin-left: 20px;
  margin-right: 20px;
  border: 1px solid #eee;
}

.articleForm {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  background-color: #ffffff;
  padding: 10px;
  padding-right: 30px;
}
</style>