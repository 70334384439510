<template>
  <el-carousel :interval="5000">
    <el-carousel-item>
      <img src="http://makuan.cc:9000/basefiles/PNG/20221228/1608122554716360704.png" style="height:100%; width:100%;">
    </el-carousel-item>
    <el-carousel-item>
      <img src="http://makuan.cc:9000/basefiles/PNG/20221228/1608123438552682496.png" style="height:100%; width:100%;">
    </el-carousel-item>
    <el-carousel-item>
      <img src="http://makuan.cc:9000/basefiles/PNG/20221228/1608124010148237312.png" style="height:100%; width:100%;">
    </el-carousel-item>
    <el-carousel-item>
      <img src="http://makuan.cc:9000/basefiles/PNG/20221228/1608124602720014336.png" style="height:100%; width:100%;">
    </el-carousel-item>
  </el-carousel>
</template>

<script>
export default {
  name: "HomeArticleCarousel"
}
</script>

<style scoped>

</style>