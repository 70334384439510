import request from '../../request/article/index'
// 1获取文章byId
export function api_getCommentByArticleId(id) {
    return request({
        method: 'GET',
        url: `/comment/commentOfArticle?articleId=${id}`
    })
}

// 2保存评论
export function api_saveComment(commentObj) {
    return request({
        method: 'POST',
        data:commentObj,
        url: '/comment/commentArticle'
    })
}

// 3获取文章最近评论
export function api_getCommentOfArticleRecent(limitNum) {
    return request({
        method: 'GET',
        url: `/comment/commentOfArticleRecent?limitNum=${limitNum}`
    })
}