<template>
<div>
  <div class="isLoading">

  </div>
  <HomeArticleCarousel class="homeCarousel"></HomeArticleCarousel>
  <div  v-for="(item,index) in articleList"  class="articleItem">
    <ArticleListItemPlus
        :articleObj=item>
    </ArticleListItemPlus>
  </div>
  <div>
    <MyPagination
        :ppageSize="pageSize"
        :ppage="page"
        :ptotal="total"
        @currentChange="currentChange"
        @handleSizeChange="handleSizeChange"
    ></MyPagination>
  </div>
</div>
</template>

<script>

import MyPagination from "../../../components/MyPagination";
import ArticleListItem from "../../../components/Card/article/ArticleListItem";
import {api_getHeadPageArticlesByPage} from '@/apis/Article/index';
import ArticleListItemPlus from "../../../components/Card/article/ArticleListItemPlus";
import HomeArticleCarousel from "../../../components/Article/HomeArticleCarousel";

export default {
  name: "ArticleList",
  components:{ArticleListItem,MyPagination,ArticleListItemPlus,HomeArticleCarousel},
  data() {
    return {
      articleList: [],
      page: 0,
      pageSize: 10,
      total: 0,
      isLoading: true,
      fieldVal:'',
      selectKey:'title'
    }
  },methods:{
    currentChange(num) {
      // console.log("父组件页码改变")
      this.page = num
      this.getArticleByPage(this.pageSize,this.page);
    },
    handleSizeChange(num){
      // console.log("父组件页大小改变")
      this.pageSize = num
      this.getArticleByPage(this.pageSize,this.page);
    },
    getArticleByPage(pSize,pPage){
      let loading = this.$loading({
        lock: this.isLoading,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let that=this;
      api_getHeadPageArticlesByPage(pPage,pSize,'','').then(resp => {
        // console.log("返回响应完整结果：",resp.data.data.result)
        if (resp.data.status==200) {
          that.isLoading=false;
          that.articleList=resp.data.data.result.list;
          that.total=resp.data.data.result.total;
        } else {
          that.$message({message: resp.data.data.message, type: 'error', showClose: true})
        }
      }).catch(err => {
        that.$message({message: err, type: 'error', showClose: true});
      });
      loading.close();
    },
    getArticleByHeadSearch(articleTitle){
      api_getHeadPageArticlesByPage(0,20,articleTitle,'').then(resp => {
        // console.log("返回响应完整结果：",resp.data.data.result)
        if (resp.data.status==200) {
          this.articleList=resp.data.data.result.list;
          this.total=resp.data.data.result.total;
        } else {
          this.$message({message: resp.data.data.message, type: 'error', showClose: true})
        }
      })
    }
  },
  created() {
      this.getArticleByPage(this.pageSize,this.page);
  },watch:{
    // 这个用于解决跳转相同组件的时候不刷新页面数据的问题
    '$route' (to, from) {
      let title= this.$route.params.articleTitle;
      if (null!==title&&''!==title&&undefined!==title){
        this.getArticleByHeadSearch(title);
      }else {
        this.getArticleByPage(this.pageSize,this.page);
      }
    }
  }

}
</script>

<style scoped>
.articleItem{
  margin-bottom: 10px;
}


.homeCarousel{
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
}
</style>