<template>
  <div class="mainArea">
    <div class="title">
      <h1 style="font-size:50px">《{{resultContent.title}}》</h1>
      <!-- //<span>
      //<button type="button"
      //      @click="copyHerf"
      //      v-clipboard:copy="sthToCopy"
      //      v-clipboard:success="onCopy"
      //      v-clipboard:error="onError">Copy!</button>
      //</span> -->
       <br/>
      <el-tag
          v-for="item in articleHeadTags"
          :key="item.id"
          effect="plain">
        {{ item.tagName }}
      </el-tag>
    </div>
    <div class="contentMain">
      <MarkdownEditor :editor="meEditor" ref="myEditor"></MarkdownEditor>
    </div>
    <div class="like" v-if="showButtonFlag">
      <el-row>
        <el-button type="primary" icon="el-icon-edit" circle></el-button>
        <el-button type="success" icon="el-icon-thumb" circle @click="likeArticle"></el-button>
      </el-row>
    </div>
    <div class="commentAreaClass">
      <commentArea :commentTreeData="commentData" :defaultProps="defaultProps"
                   @refreshCommentChange="reRequestCommentList"/>
    </div>
  </div>
</template>

<script>
import {
  MQ_PROTOCOL,
  MQ_IP,
  MQ_PORT,
  MQ_ADDRESS,
  MQ_ACCOUNT,
  MQ_PASSWORD,
  MQ_SUBSCRIPT,
  MQ_VIRTUALHOST
} from '../../../utils/CommonConsts';

import Stomp from 'stompjs';

import MarkdownEditor from "@/components/Editor/MarkdownEditor";
// 组件名称不能用大写 did you register the component correctly? For recursive components, make sure to provide the "name" option. found in ---> <ArticleDetail
import commentArea from "@/components/Comment/CommentArea";
import {api_getArticleById} from '@/apis/Article/index';
import {api_likeArticleById} from '@/apis/Like/index';
import {api_getTagByIdList} from '@/apis/Tag/index';
import {api_getCommentByArticleId} from '@/apis/Comment/index';

export default {
  name: "ArticleDetaile",
  components:{MarkdownEditor,commentArea},
  data() {
    return {
      meEditor: {
        value: '',
        toolbarsFlag: true, //这个表示是否展示工具栏目
        subfield: false, //这个表示是否展示编辑窗口
        defaultOpen: 'preview' ,//默认打开预览窗口
        scrollStyle: true ,//开启滚动条模式
        toolbars :{           // 设置为true表示工具栏里面显示
          navigation:true ,   // 默认开启导航目录
          readmodel: true     // 沉浸阅读本质是全屏
        }
      },
      resultContent:{},
      articleHeadTags:[],
      showButtonFlag:false,
      sthToCopy:'',
      address: null, //连接地址
      client: null,
      subscribes: {},
      allMessageFlag: true,
      commentData:[],
      defaultProps: {
        children: 'children',
        label: 'commentContent'
      }
    }
  },
  mounted() {
    this.initArticlePage();
    this.initButtonFlag();
    this.initCommentTree();



    let vm = this;
    vm.address = MQ_PROTOCOL + '://' + MQ_IP + ':' + MQ_PORT + '/' + MQ_ADDRESS;
    // vm.address = MQ_PROTOCOL + '://' + MQ_IP + ':' + MQ_PORT + '/';
    vm.connect();
  },
  methods:{
    onCopy: function (e) {
      alert('You just copied: ' + e.text)
     },
     copyHerf: function (e) {
             this.sthToCopy=window.location.href;
             this.sthToCopy="Hlllo world";
     },
     onError: function (e) {
      console.log(e)
      alert('Failed to copy texts')
     },
    initButtonFlag(){
      let token=localStorage.getItem('accessToken');
      if (!null==token||!''==token){
        this.showButtonFlag = true;
      }
    },
    initArticle(){
      this.getArticleById();
    },
    getArticleById(){
      let that=this;
      api_getArticleById(this.$route.params.articleId).then(resp => {
        // console.log("返回响应完整结果：",resp.data.data.result);
        if (resp.data.status==200) {
          that.resultContent = resp.data.data.result;
          // 1 使用html换行样式有问题使用markdown格式渲染
          //  that.meEditor.value=that.resultContent.htmlContent;
          // 2 使用markdown进行渲染
            this.$refs.myEditor.editor.value=that.resultContent.markContent;
        } else {
          that.$message({message: resp.data.data.message, type: 'error', showClose: true})
        }
      }).catch(err => {
        that.$message({message: err, type: 'error', showClose: true});
      });
    },
    getTagByIdList(){
      let tagIds=this.resultContent.tagIds;
      // console.log("这是我要查询的"+tagIds)
      let that = this;

      api_getTagByIdList(tagIds).then(resp => {
        if (resp.data.status==200) {
          // // console.log("根据文章tag列表查询结果为：",resp.data.data.result);
          that.articleHeadTags = resp.data.data.result;
        } else {
          that.$message({message: resp.data.data.message, type: 'error', showClose: true})
        }
      });
    },
    initArticlePage(){
      this.initArticle();
    },
    setPageByHand(){
      that.$refs.myEditor.editor.value="";
    },
    likeArticle(){
      api_likeArticleById(this.resultContent.articleId).then(resp => {
        if (resp.data.status==200) {
          this.$message({message: "您正在点赞", type: 'success', showClose: true});
        } else {
          this.$message({message: resp.data.data.message, type: 'error', showClose: true})
        }
      });

    },
    //连接消息队列
    connect() {
      let vm = this;
      vm.client = Stomp.client(vm.address);
      let headers = {
        login: MQ_ACCOUNT,
        passcode: MQ_PASSWORD,
        //虚拟主机，默认“/”
        host: MQ_VIRTUALHOST}
      vm.client.connect(headers,connectSUCCESS,connectFAILED)
      function connectSUCCESS(e){
        console.info('连接成功!');
        vm.subscribe();
      };
      function connectFAILED(e){
        console.info('连接失败!')}
     },
    //断开连接
    disconnect(){
      let vm = this;
      if(vm.client && vm.client.connected) {
        vm.client.disconnect( () => {
          // vm.unsubscribe();
          vm.subscribes = {};
          console.info('断开连接!')
        })
      }
    },

    //订阅
    subscribe() {
      let vm = this;
      if(!vm.client) {
        console.info('暂未连接')
        return;
      };
      if(!vm.client.connected) {
        console.info('暂未连接')
        return;
      }
      if (vm.subscribes[MQ_SUBSCRIPT]){
        console.info('已经订阅了')
        return;
      }
      vm.subscribes[MQ_SUBSCRIPT] = vm.client.subscribe(MQ_SUBSCRIPT,  (e) => {
        if (e.body.length=='{}'){
          vm.allMessageFlag=false;
        }
        //处理数据
        if (vm.allMessageFlag){
          let messageObj =JSON.parse(e.body);
          vm.siteAlert(messageObj.msg);
        }
      });
    },

    //取消订阅
    unsubscribe() {
      let vm = this;
      if (vm.subscribes[MQ_SUBSCRIPT]){
        vm.subscribes[MQ_SUBSCRIPT].unsubscribe();
        delete vm.subscribes[MQ_SUBSCRIPT];
      }
    },
    siteAlert(msg){
      const h = this.$createElement;
      this.$notify.info({
        title: '全站消息',
        message: h('i', { style: 'color: teal'}, msg),
        duration: 0
      });
    },
    initCommentTree() {
      let that = this;
      api_getCommentByArticleId(that.$route.params.articleId).then(resp => {
        if (resp.data.status==200) {
          that.commentData = resp.data.data.result;
        } else {
          that.$message({message: resp.data.data.message, type: 'error', showClose: true})
        }
      });
    },
    reRequestCommentList(){
      // console.log("我是详情页面，组件调用我了") 评论完毕后组件调用查询评论方法
      this.initCommentTree();
    }
  },
  watch:{
    $route() {
      location.reload();
    },
    // 监听 resultContent 变量 item1为新值，item2为旧值
    resultContent(newData,oldData){
      // console.log("newData:"+JSON.stringify(newData));
      this.getTagByIdList();
    }
  }
}
</script>

<style scoped>
.mainArea{
  background-color: #f7f7f6;
}

.like{
  text-align: right;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.title{
  padding-top: 10px;
  margin-bottom: 10px;
}

.el-tag{
  margin-left: 10px;
}

.commentAreaClass{
  margin-top: 20px;
  border-radius: 20px;
}
</style>