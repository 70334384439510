<template>
  <div>
    <!--  1订单搜索-->
    <div class="searchOrder">
      <!--失去焦点 或者 用户按下回车触发搜索-->
      <el-input @change="searchInput" v-model="input" placeholder="请输入内容"></el-input>
      <el-button @click="searchInput" type="primary">查询</el-button>
      <el-button @click="dialogVisible = true" type="primary">添加</el-button>
    </div>
    <!--  2订单展示列表-->
    <!--width 没有给的均分剩下的宽度-->
    <div class="tableWrapper">
      <el-table
          :data="tableData"
          border>
        <!--选择框-->
        <el-table-column
            type="selection">
        </el-table-column>
        <!--数据区域-->
        <el-table-column
            prop="orderName"
            label="订单名称"
            width="120">
        </el-table-column>
        <el-table-column
            prop="amount"
            label="总金额">
        </el-table-column>
        <el-table-column
            prop="price"
            label="单价">
        </el-table-column>
        <el-table-column
            prop="goodsNum"
            label="购置数量">
        </el-table-column>
        <el-table-column
            prop="orderDate"
            label="下单日期">
        </el-table-column>
        <el-table-column
            prop="payDate"
            label="支付日期">
        </el-table-column>
        <el-table-column
            prop="modelName"
            label="系统模块名称">
        </el-table-column>
        <el-table-column
            label="操作">
          <template slot-scope="scope">
            <!--点击可以触发两个参数，一个是索引 一个是行数据-->
            <el-button
                size="mini"
                @click="handleEdit(scope.$index, scope.row)">编辑
            </el-button>
            <el-button
                size="mini"
                type="danger"
                @click="handleDelete(scope.$index, scope.row)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--  3订单分页-->
    <div>
      <MyPagination :ppageSize="pageSize" :ppage="page" :ptotal="total" @currentChange="currentChange"></MyPagination>
    </div>
    <!--  4 订单编辑添加-->
    <OrderDialog :dialogVisible="dialogVisible" @dialogChange="dialogChange"></OrderDialog>
  </div>
</template>

<script>

import {api_getOrders} from '../../../apis/Order/index';
import MyPagination from "../../../components/MyPagination";
import OrderDialog from "./OrderDialog";


export default {
  name: "OrderList",
  components: {OrderDialog, MyPagination},
  data() {
    return {
      input: '',
      tableData: [],
      page: 1,
      pageSize: 2,
      total: 0,
      orderName: '',
      dialogVisible: false
    }
  },
  methods: {
    handleEdit(mIndex, mRowData) {
      // console.log(mIndex, mRowData)
    },
    handleDelete(mIndex, mRowData) {
      // console.log(mIndex, mRowData)
    },
    getOrderList(orderName) {
      api_getOrders(this.page, this.pageSize,orderName).then(resp => {
        // console.log('查询到数据', resp.data)

        this.tableData = resp.data.data.result.list
        this.total = resp.data.data.result.total
        this.pageSize = resp.data.data.result.pageSize
        // console.log(this.tableData, this.total, this.pageSize)
      }).catch(error => {
        if (error !== 'error') {
          this.$message({type: 'error', message: '订单加载失败', showClose: true})
        }
      })
    },
    currentChange(num) {
      // console.log("父组件页码改变")
      this.page = num
      this.getOrderList(this.input)
    },
    searchInput(){
      this.getOrderList(this.input)
    },
    dialogChange(){
      this.dialogVisible=false
    }
  },
  created() {
    // console.log('看看执行了没有')
    this.getOrderList('')
  }
}
</script>

<style lang="less" scoped>
.searchOrder {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  display: flex; // 这样就变成一行了
}

.searchOrder button {
  margin-left: 20px;
}

.tableWrapper {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}
</style>