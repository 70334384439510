import request from '../../request/article/index'
// 1获取文章byId
export function api_getArticleById(id) {
    return request({
        method: 'GET',
        url: `/textContent/article?id=${id}`
    })
}
// 2 保存记账
export function api_saveMoneyBookItem(articleForm) {
    return request({
        method: 'POST',
        data:articleForm,
        url: '/book/money'
    })
}

// 3 get article list
export function api_getBookMoneyByPage(page,pageSize,title) {
    return request({
        method: 'GET',
        url: `/book/money?page=${page}&pageSize=${pageSize}&title=${title}`
    })
}

//4 根据id删除文章
export function api_delArticleById(articleId) {
    return request({
        method: 'GET',
        url: `/textContent/temp/article/${articleId}`
    })
}

// 5 获取月维度账单标签分布
export function api_getBookMoneyAnalysis(monthString) {
    return request({
        method: 'GET',
        url: `/book/money-analysis?monthString=${monthString}`
    })
}


// 5 获取月维度账单标签分布（需要标注响应类型为二进制流而不是字符串JSON）
export function api_getBookMoneyAnalysisRawList(monthString) {
    return request({
        responseType: "blob",
        method: 'GET',
        url: `/book/money-analysis-raw-download?monthString=${monthString}`
    })
}